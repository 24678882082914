import React, {useState} from "react";
import {useGetCounsellorGroupSessionsApi} from "../services/useCounsellorGroupSessionApi";
import {
  CounsellorGroupSessionInterface,
} from "../../../core/interface/counsellor-group-sessions/CounsellorGroupSessionInterface";
import {Button, Pagination, Spinner, Table, Tooltip} from "flowbite-react";
import {StageType} from "../../../core/enums/stage/stageType";
import {getStageShortName} from "../../../shared/utils/getStageShortName";
import {GroupActionPlanStatus} from "../../../core/enums/counsellor-group-session/GroupActionPlanStatus";
import {GroupSessionStatus} from "../../../core/enums/counsellor-group-session/GroupSessionStatus";
import {Link} from "react-router-dom";
import {HiArrowSmRight} from "react-icons/hi";

const statusButtonStyles: Record<GroupActionPlanStatus, string> = {
  [GroupActionPlanStatus.NOT_STARTED]: "bg-blue-600 text-white",
  [GroupActionPlanStatus.CREATED]: "bg-blue-600 text-white",
  [GroupActionPlanStatus.REJECTED]: "bg-red-600 text-white",
  [GroupActionPlanStatus.APPROVER_ASSIGNED]: "bg-yellow-600 text-white",
  [GroupActionPlanStatus.COMPLETED]: "bg-green-600 text-white",
};

const CounsellorGroupSessions: React.FC = () => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, isLoading} = useGetCounsellorGroupSessionsApi(currentPage, pageSize);
  const sessions: CounsellorGroupSessionInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const renderGroupActionPlanButton = (session: CounsellorGroupSessionInterface) => {
    const buttonStyle = statusButtonStyles[session.group_action_plan.group_action_plan_status];
    if (!buttonStyle) return null;

    return (
      <Link to={`counsellor-create-group-action-plan/${session.group_id}`}
            state={{customerGrade: session.plan_applicable_grades}}>
        <Tooltip content="Group Action Plan" placement="top">
          <Button
            className={`rounded-full h-8 w-8 flex items-center justify-center p-5 ${buttonStyle}`}
            color="blue"
            size="xs">
            GAP
          </Button>
        </Tooltip>
      </Link>
    );
  };

  const renderTableRows = () => {
    if (isLoading) {
      return (
        <Table.Row>
          <Table.Cell colSpan={6}>
            <div className="flex justify-center items-center py-5">
              <Spinner size="lg"/>
            </div>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (totalCount === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan={6}>
            <div className="flex justify-center items-center py-5">
              <div className="text-center text-gray-500">No counsellor group session records found.</div>
            </div>
          </Table.Cell>
        </Table.Row>
      );
    }

    return sessions.map((session, index) => (
      <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell>{session.group_name}</Table.Cell>
        <Table.Cell>{session.plan_name}</Table.Cell>
        <Table.Cell>{session.session_name}</Table.Cell>
        <Table.Cell>{session.session_mode}</Table.Cell>
        <Table.Cell>{session.customer_count}</Table.Cell>
        <Table.Cell>
          <div className="flex items-center space-x-2">
            <div>
              {session.status === GroupSessionStatus.COMPLETED ? (
                <div className={`flex justify-center items-center`}>
                  <Tooltip content={session.session_name} placement="top">
                    <Button
                      disabled size="xs"
                      className={`rounded-full h-8 w-8 flex items-center justify-center p-5 bg-green-500 text-white`}>
                      {getStageShortName(session.stage_type)}
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                <Link to={`counsellor-group-session-details/${session.group_id}`}>
                  <Tooltip content={session.session_name} placement="top">
                    <Button
                      className={`rounded-full h-8 w-8 flex items-center justify-center p-5`}
                      color="blue"
                      size="xs"
                    >
                      {getStageShortName(session.stage_type)}
                    </Button>
                  </Tooltip>
                </Link>
              )}
            </div>
            {session.stage_type === StageType.B2B_GROUP_COUNSELLING_SESSION && <HiArrowSmRight/>}
            {session.stage_type === StageType.B2B_GROUP_COUNSELLING_SESSION && (
              session.status === GroupSessionStatus.COMPLETED ? renderGroupActionPlanButton(session) : (
                <div className={`flex justify-center items-center`}>
                  <Tooltip content="Group Action Plan" placement="top">
                    <Button
                      disabled size="xs"
                      className={`rounded-full h-8 w-8 flex items-center justify-center p-5 bg-gray-500 text-white`}>
                      GAP
                    </Button>
                  </Tooltip>
                </div>
              )
            )}
          </div>
        </Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <div>
      <header className="flex flex-col mb-5">
        <h1 className="text-2xl font-semibold text-gray-900">Group Sessions</h1>
      </header>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <Table striped>
          <Table.Head className="border-b border-gray-200">
            <Table.HeadCell className="text-gray-500">Group Name</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Plan Name</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Session Name</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Session Mode</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Customer Count</Table.HeadCell>
            <Table.HeadCell className="text-gray-500">Stages</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">{renderTableRows()}</Table.Body>
        </Table>
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing{" "}
              <span className="font-semibold text-black">
                {pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}
              </span>{" "}
              of <span className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CounsellorGroupSessions;
