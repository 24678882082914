import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
  addCustomerApi,
  disableCustomerApi, exportCustomersApi,
  fetchCustomerDetailsApi, fetchCustomerPlansApi,
  fetchCustomersApi,
  updateCustomerApi,
  uploadCustomersApi
} from "./customerApi";
import {useToast} from "../../../../shared/components/notification-messages/toastNotification";
import {CustomersCsvUploadRequestProps} from "../../../../core/interface/customer/UploadCustomerStepProps";
import {ToastType} from "../../../../core/enums/toastType";
import {AddAndUpdateCustomerRequest} from "../../../../core/interface/customer/AddCustomerProps";
import {CustomResponse, DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import {CustomerInterface, CustomerRequest} from "../../../../core/interface/customer/CustomerInterface";
import {DisableRequest} from "../../../../core/interface/DisableRequest";
import {CustomerPlanInterface, CustomerPlanRequest} from "../../../../core/interface/customer/CustomerPlanInterface";

export const useUploadCustomersApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CustomersCsvUploadRequestProps) => uploadCustomersApi(data),

    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['entityPlans']});
      queryClient.invalidateQueries({queryKey: ['entityPlanDetails']});
      const message = 'Customers csv uploaded successfully!';
      showToastMessage({type: ToastType.Success, message});
      return response;
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['entityPlan']});
    }
  })
}

export const useAddCustomerApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (addCustomerRequest: AddAndUpdateCustomerRequest) => addCustomerApi(addCustomerRequest),

    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['entityPlanCustomers']});
      const message = 'Customers Added successfully!';
      showToastMessage({type: ToastType.Success, message});
      return response;
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['entityPlanCustomers']});
    }
  })
}

export const useUpdateCustomerApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateCustomerRequest: AddAndUpdateCustomerRequest) => updateCustomerApi(updateCustomerRequest),

    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['entityPlanCustomers']});
      const message = 'Customers Added successfully!';
      showToastMessage({type: ToastType.Success, message});
      return response;
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['entityPlanCustomers']});
    }
  })
}

export const useGetCustomersApi = (customerRequest: CustomerRequest) => {
  return useQuery<CustomResponse<CustomerInterface[]>>({
    queryKey: ['customers', {customerRequest}],
    queryFn: () => fetchCustomersApi(customerRequest),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchCustomerDetailsApi = (customerId: string | undefined) => {
  return useQuery<DetailsCustomDataResponse<CustomerInterface>>({
    queryKey: ['customer', {customerId}],
    queryFn: () => fetchCustomerDetailsApi(customerId),
    enabled: !!customerId
  });
}

export const useDisableCustomerApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({data}: { data: DisableRequest }) => disableCustomerApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['customers']});
      queryClient.invalidateQueries({queryKey: ['entityPlanCustomers']});
      const message = 'Customer deleted successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['customers']});
      queryClient.invalidateQueries({queryKey: ['entityPlanCustomers']});
    }
  })
}

export const useGetCustomerPlansApi = (customerPlanRequest: CustomerPlanRequest) => {
  return useQuery<CustomResponse<CustomerPlanInterface[]>>({
    queryKey: ['customers', {customerPlanRequest}],
    queryFn: () => fetchCustomerPlansApi(customerPlanRequest),
    retry: false,
    staleTime: 300000,
  });
};

export const useExportCustomersApi = (isExportCustomer: boolean, entityId: any, entityPlanId: any) => {
  return useQuery<any>({
    queryKey: ['exportCustomers', {entityId, entityPlanId}],
    queryFn: () => exportCustomersApi(entityId, entityPlanId),
    retry: false,
    staleTime: 300000,
    enabled: isExportCustomer
  })
}
