import React, {useState} from "react";
import {useGetCounsellorIndividualSessionsApi} from "../services/useCounsellorIndividualSessionsApi";
import {Button, Pagination, Spinner, Table, Tooltip} from "flowbite-react";
import {HiArrowSmRight} from "react-icons/hi";
import {Link} from "react-router-dom";
import {
  CounsellorIndividualSessionInterface
} from "../../../core/interface/counsellor-individual-sessions/CounsellorIndividualSessionInterface";
import {getStageShortName} from "../../../shared/utils/getStageShortName";
import {StageState} from "../../../core/enums/stage/StageState";

const CounsellorIndividualSessions: React.FC = () => {

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, isLoading} = useGetCounsellorIndividualSessionsApi(currentPage, pageSize);
  const counsellorIndividualSession: CounsellorIndividualSessionInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-5">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">Individual Sessions</h1>
      </div>

      <div className={`overflow-x-auto relative shadow-md sm:rounded-lg`}>
        <div className="overflow-x-auto">
          <Table striped>
            <Table.Head className='border-b border-gray-200'>
              <Table.HeadCell className={`text-gray-500`}>Customer Name</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Grade</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Plan Name</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Stages</Table.HeadCell>
            </Table.Head>

            <Table.Body className="divide-y">
              {isLoading ? (
                <Table.Row>
                  <Table.Cell colSpan={4}>
                    <div className="flex justify-center items-center py-5">
                      <Spinner size="lg"/>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : totalCount === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan={4}>
                    <div className="flex justify-center items-center py-5">
                      <div className="tet-center text-gray-500">No counsellor individual session records found.</div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : (
                <>
                  {counsellorIndividualSession.map((session, index) => (
                    <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>
                        {session.customer_name}
                      </Table.Cell>
                      <Table.Cell>
                        {session.customer_grade}
                      </Table.Cell>
                      <Table.Cell>
                        {session.customer_plan_name}
                      </Table.Cell>
                      <Table.Cell className={`flex items-center space-x-2`}>
                        {session.stages
                          .slice()
                          .sort((a, b) => a.stage_order_number - b.stage_order_number)
                          .map((stage, index) => (
                            <React.Fragment key={index}>
                              {stage.stage_state === StageState.NOT_STARTED || stage.stage_state === StageState.COMPLETED ||
                              stage.progress_stage_states?.some((state) => state === "COUNSELLOR-CREATE-ACTION-PLAN") ? (
                                <div className={`flex justify-center items-center`}>
                                  <Tooltip content={stage.stage_name} placement="top">
                                    <Button
                                      disabled
                                      color="blue"
                                      size="xs"
                                      className={`rounded-full h-8 w-8 flex items-center justify-center p-5 
                                    ${stage.stage_state === StageState.COMPLETED ||
                                      stage.progress_stage_states?.some((state) => state === "COUNSELLOR-CREATE-ACTION-PLAN")
                                        ? "bg-green-500 text-white"
                                        : "bg-gray-200 text-gray-400"
                                      }`}>
                                      {getStageShortName(stage.stage_type)}
                                    </Button>
                                  </Tooltip>
                                </div>
                              ) : (
                                <Link
                                  to={`counsellor-individual-session-details/${stage?.stage_id}`}
                                  state={{customerGrade: session.customer_grade}}
                                >
                                  <Tooltip content={stage.stage_name} placement="top">
                                    <Button
                                      className={`rounded-full h-8 w-8 flex items-center justify-center p-5 bg-blue-600 text-white`}
                                      color="blue"
                                      size="xs"
                                    >
                                      {getStageShortName(stage.stage_type)}
                                    </Button>
                                  </Tooltip>
                                </Link>
                              )}
                              {index < session.stages.length - 1 && <HiArrowSmRight/>}
                            </React.Fragment>
                          ))}
                      </Table.Cell>

                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
        </div>
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CounsellorIndividualSessions;
