export enum StageGroupType {
  BULK_SESSION = "BULK-SESSION",
  ASSESSMENT = "ASSESSMENT",
  ASSESSMENT_REPORT = "ASSESSMENT-REPORT",
  INDIVIDUAL_SESSION = "INDIVIDUAL-SESSION",
  GROUP_SESSION = "GROUP-SESSION",
  ACTION_PLAN_REPORT = "ACTION-PLAN-REPORT",
  GROUP_ACTION_PLAN_REPORT = "GROUP-ACTION-PLAN-REPORT",
  EXPERT_REPORT = "EXPERT-REPORT",
  PARENT_REPORT = "PARENT-REPORT",
  FINAL_REPORT = "FINAL-REPORT"
}
