import React, {useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAssignReviewerGroupApi, useGetGroupReviewersApi} from "../services/useAssignApproversApi";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Pagination, Spinner, Table} from "flowbite-react";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";
import {
  GroupReviewerInterface
} from "../../../core/interface/staff-assign-approver/group-assign-approver/GroupReviewerInterface";

const GroupReviewers: React.FC = () => {
  const navigate = useNavigate();
  const {id} = useParams<{ id: string }>();
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [selectedReviewer, setSelectedReviewer] = useState<GroupReviewerInterface | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, isLoading} = useGetGroupReviewersApi(id, currentPage, pageSize);
  const groupReviewers: GroupReviewerInterface[] = useMemo(() => data?.data.items || [], [data]);
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const {mutate: assignReviewer} = useAssignReviewerGroupApi();

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleRowClick = (row: GroupReviewerInterface) => {
    setSelectedReviewer(row);
  };

  const handleAssignReviewer = () => {
    setLoading(true);
    const mutationData = {
      groupId: id,
      data: {
        approver_id: selectedReviewer?.id,
        approver_name: `${selectedReviewer?.first_name} ${selectedReviewer?.last_name}`
      }
    }
    assignReviewer(mutationData, {
      onSuccess: () => navigate('/staff-assign-approvers'),
      onSettled: () => setLoading(false)
    });
  }

  return (
    <div>
      <div className="mb-4">
        <BackButton to="/staff-assign-approvers" label="Back"/>
        <h1 className="text-xl font-bold mb-4">Group Reviewers</h1>
        <CustomBreadcrumb
          items={[
            {label: "Assign Approvers", to: "/staff-assign-approvers"},
            {label: "Group Reviewers"},
          ]}
        />
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <Table striped>
          <Table.Head className="border-b border-gray-200">
            <Table.HeadCell>Host Name</Table.HeadCell>
            <Table.HeadCell>Host Role</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {isLoading ? (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : totalCount === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div className="flex justify-center items-center py-5">
                    <div className="tet-center text-gray-500">No group reviewer records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : (
              <>
                {groupReviewers.map((reviewer) => (
                  <Table.Row
                    key={reviewer.id}
                    className={`cursor-pointer hover:bg-gray-100 ${
                      selectedReviewer?.id === reviewer.id ? "bg-blue-50" : ""
                    }`}
                    onClick={() => handleRowClick(reviewer)}
                  >
                    <Table.Cell>
                      <label className="flex items-center space-x-4">
                        <input
                          type="radio"
                          name="approver"
                          checked={selectedReviewer?.id === reviewer.id}
                          onChange={() => handleRowClick(reviewer)}
                          className="cursor-pointer"
                        />
                        <span>{reviewer.first_name} {reviewer.last_name}</span>
                      </label>
                    </Table.Cell>
                    <Table.Cell>{reviewer.role}</Table.Cell>
                  </Table.Row>
                ))}
              </>
            )}
          </Table.Body>
        </Table>
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>

      {totalCount > 0 && (
        <div className="flex justify-start mt-6">
          <CustomLoaderButton loading={loading} color='blue' disabled={!selectedReviewer} onClick={handleAssignReviewer}>
            Assign
          </CustomLoaderButton>
        </div>
      )}
    </div>
  );
};

export default GroupReviewers;
