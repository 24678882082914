import React, {useState} from "react";
import {Badge, Button, Card, Spinner} from "flowbite-react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
  CustomersCsvUploadRequestProps,
  UploadCustomerStepProps
} from "../../../../../core/interface/customer/UploadCustomerStepProps";
import CustomLoaderButton from "../../../../../shared/components/custom-buttons/customLoaderButton";
import CustomerCsvFileUpload from "../../../../users/customers/components/CustomerCsvFileUpload/customerCsvFileUpload";
import {useUploadCustomersApi} from "../../../../users/customers/services/useCustomerApi";
import {useEntityPlanCustomerUploadStatusApi, useFetchEntityPlanDetailsApi} from "../../services/useEntityPlanApi";
import {EntityPlanInterface} from "../../../../../core/interface/entity/EntityPlanInterface";
import {EntityPlanStatus} from "../../../../../core/enums/entityPlanStatus";
import {
  EntityPlanCustomerUploadStatusInterface
} from "../../../../../core/interface/entity/EntityPlanCustomerUploadStatusInterface";

const UploadCustomerCsvStep: React.FC<UploadCustomerStepProps> = (props: UploadCustomerStepProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  
  const [loading, setLoading] = useState(false);
  const {entityName} = location.state || {};
  const {entityId} = useParams<{ entityId: string }>();
  const {data, isLoading} = useFetchEntityPlanDetailsApi(entityId, props.entityPlanId);
  const entityPlanDetails: EntityPlanInterface | undefined = data?.data;
  const {
    data: progressData,
  } = useEntityPlanCustomerUploadStatusApi(entityId, props.entityPlanId);
  const entityPlanCustomerUploadStatus: EntityPlanCustomerUploadStatusInterface | undefined = progressData?.data;
  const {mutate: uploadCustomers} = useUploadCustomersApi();
  
  const handleFileChange = (file: File | null) => {
    setSelectedFile(file);
  };
  
  const handleUploadClick = () => {
    setLoading(true);
    const customersCsvData: CustomersCsvUploadRequestProps = {
      entityId: entityId,
      entityPlanId: props.entityPlanId,
      customersCsvFile: selectedFile
    };
    
    uploadCustomers(customersCsvData, {
      onSuccess: () => {
        navigate(`/entities/${entityId}/entity-plans`, {
          state: {
            entityName,
          },
        });
      },
      onSettled: () => {
        setLoading(false);
      },
      onError: (error: any) => {
        setLoading(false);
      }
    });
  };
  
  return (
    <div className="w-full flex items-center justify-center py-10">
      {isLoading ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      ) : (
        <Card className="w-1/2">
          {entityPlanDetails?.status === EntityPlanStatus.SETUP_ADD_CUSTOMER_INPROGRESS ? (
            <div className="p-6">
              <div className="flex justify-center items-center pb-4">
                <Spinner aria-label="Customer uploading" size="xl"/>
              </div>
              <div className="text-center mb-4">
                <p className="flex items-center text-lg font-medium text-gray-800">
                  You uploaded: {entityPlanCustomerUploadStatus?.customers_counts ?? 0} customers. <Badge className="ml-2" color={"success"}>Completed</Badge>: {entityPlanCustomerUploadStatus?.customers_account_created_count ?? 0}
                  <Badge className="ml-2 mr-1" color={"info"}>Inprogress</Badge>: {(entityPlanCustomerUploadStatus?.customers_counts ?? 0) - (entityPlanCustomerUploadStatus?.customers_account_created_count ?? 0)}
                </p>
              </div>
              <div className='flex justify-center items-center mb-5'>
                <Link to={`/entities/${entityId}/entity-plans`} state={{entityName}}>
                  <Button outline color='blue' size='lg'>
                    Go Back Entity Plan
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-center items-center pb-4">
                <h2 className="text-xl font-semibold">Upload Customer CSV</h2>
              </div>
              
              <CustomerCsvFileUpload
                label="Upload file"
                maxFileSize={1024 * 1024}
                onFileChange={handleFileChange}
                sampleCsvUrl="https://images.merementor.in/entity-customer-csv-upload-sample.csv"
              />
              
              <div className="mt-6 flex justify-center space-x-4">
                <CustomLoaderButton size='lg' className='px-10' loading={loading} disabled={!selectedFile} color='blue'
                                    onClick={handleUploadClick}>
                  Upload
                </CustomLoaderButton>
              </div>
            </div>
          )}
        </Card>
      )}
    </div>
  );
};

export default UploadCustomerCsvStep;
