import React, {useEffect, useMemo, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import BackButton from "../../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../../shared/components/custom-breadcrumb/customBreadcrumb";
import FormInput from "../../../../shared/components/form-wrapper/FormInput";
import FormSelect from "../../../../shared/components/form-wrapper/FormSelect";
import {BusinessModel} from "../../../../core/enums/businessModel";
import {Region} from "../../../../core/enums/region";
import CustomLoaderButton from "../../../../shared/components/custom-buttons/customLoaderButton";
import {Avatar, Button, FileInput, Label} from "flowbite-react";
import {useNavigate, useParams} from "react-router-dom";
import {EntityType} from "../../../../core/enums/entityType";
import FormTextarea from "../../../../shared/components/form-wrapper/FormTextarea";
import {useCreateEntityApi, useFetchEntityDetailsApi, useUpdateEntityApi} from "../services/useEntityApi";
import {EntityInterface} from "../../../../core/interface/entity/EntityInterface";
import {MdOutlineImageNotSupported} from "react-icons/md";
import EntityAdminView from "../../../users/entity-admin/components/entity-admin-view/entityAdminView";
import Stepper from "../../../../shared/components/custom-stepper/customStepper";
import {useCreateEntityAdminApi} from "../../../users/entity-admin/services/useEntityAdminApi";
import {AddEntityAdminRequest} from "../../../../core/interface/entity-admin/AddEntityAdminProps";
import {Gender} from "../../../../core/enums/Gender";
import {genderLabels} from "../../../../shared/utils/custom-lavel/genderLabels";

const STEPS = ['Step-1: Create Entity', 'Step-2: Add Entity Admin'];

const entityValidationSchema = (isCoBranding: boolean) =>
  yup.object({
    business_model: yup.string().required("Business Model is required"),
    name: yup.string().required("Name is required"),
    type: yup.string().required("Type is required"),
    region: yup.string().required("Region is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    pincode: yup.string().required("Pincode is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    phone_number: yup.string()
      .required('Phone number is required')
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .test('not-starts-with-0-5', 'Enter a valid phone number', (value: any) => {
        return value && !/^[0-5]/.test(value);
      }),
    logo_url: isCoBranding
      ? yup.mixed()
        .required("Logo is required")
        .test("fileSelected", "Please upload a logo file", value => value && (value instanceof File || !!value))
      : yup.mixed(),
    tagline: isCoBranding ? yup.string().required("Tagline is required") : yup.string(),
    description: isCoBranding ? yup.string().required("Description is required") : yup.string(),
  });

const entityAdminValidationSchema = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  gender: yup.string().required("Gender is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  phone_number: yup.string().required("Phone number is required"),
});

const CreateEntity: React.FC = () => {

  const steps = useMemo(() => STEPS, []);
  const {entityId} = useParams<{ entityId: string }>();
  const {data} = useFetchEntityDetailsApi(entityId);
  const entityDetails: EntityInterface | undefined = data?.data;

  const navigate = useNavigate();
  const businessModalOptions = useMemo(() => Object.values(BusinessModel), []);
  const regionOptions = useMemo(() => Object.values(Region), []);
  const entityTypeOptions = useMemo(() => Object.values(EntityType), []);

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  const [isCoBranding, setIsCoBranding] = useState(false);
  const [entityIdByResponse, setEntityIdByResponse] = useState<any>('');

  const {mutate: createEntity} = useCreateEntityApi();
  const {mutate: updateEntity} = useUpdateEntityApi();
  const {mutate: createEntityAdmin} = useCreateEntityAdminApi();
  const {
    register: registerEntity,
    setValue: setEntityValue,
    handleSubmit: handleSubmitEntity,
    formState: {errors: entityErrors},
  } = useForm({
    resolver: yupResolver(entityValidationSchema(isCoBranding)),
  });

  const {
    register: registerEntityAdmin,
    handleSubmit: handleSubmitEntityAdmin,
    formState: {errors: EntityAdminErrors}
  } = useForm({
    resolver: yupResolver(entityAdminValidationSchema),
  });

  const genderOptions = useMemo(() => {
    return Object.values(Gender).map(option => ({
      value: option,
      label: genderLabels[option],
    }));
  }, []);

  const onSubmitEntityForm = async (data: any) => {
    data.phone_number = `+91${data.phone_number}`;
    setLoading(true);
    const {logo_url, tagline, description, ...json_data} = data;

    if (isCoBranding) {
      json_data.co_branding = {tagline, description};
    }
    const entityFormData = {
      json_data,
      co_branding_logo: logo_url,
    };

    const updatedEntityData = {
      name: data.name,
      region: data.region,
      address: data.address,
      city: data.city,
      state: data.state,
      pincode: data.pincode,
      phone_number: data.phone_number,
      email: data.email,
    }

    if (entityId) {
      const mutationData = {entityId: entityId, data: updatedEntityData};
      updateEntity(mutationData, {
        onSuccess: () => {
          navigate('/entities');
        },
        onSettled: () => {
          setLoading(false);
        }
      });
    } else {
      setCurrentStep(1);
      createEntity(entityFormData, {
        onSuccess: (response) => {
          const idMatch = response.message.match(/with id ([a-zA-Z0-9]+)/);
          const entityId = idMatch ? idMatch[1] : null;
          setEntityIdByResponse(entityId);
          setCurrentStep(1);
        },
        onSettled: () => {
          setLoading(false);
        }
      });
    }
  };

  const handleBusinessModelChange = (e: any) => {
    const selectedBusinessModel = e.target.value;
    if (selectedBusinessModel.includes('CO-BRANDING')) {
      setIsCoBranding(true);
    } else {
      setIsCoBranding(false);
      setEntityValue('logo_url', '');
      setEntityValue('tagline', '');
      setEntityValue('description', '');
    }
  };

  useEffect(() => {
    if (entityDetails) {
      Object.entries(entityDetails).forEach(([key, value]) => {
        setEntityValue(key as any, value);
      });

      const formattedPhoneNumber = entityDetails.phone_number?.replace(/^\+91/, '') || '';
      setEntityValue("phone_number", formattedPhoneNumber);

      if (entityDetails?.co_branding?.logo_url && entityDetails?.co_branding?.tagline && entityDetails?.co_branding?.description) {
        setIsCoBranding(true);
        setSelectedImage(entityDetails?.co_branding?.logo_url);
        setEntityValue('logo_url', entityDetails?.co_branding?.logo_url);
        setEntityValue('tagline', entityDetails?.co_branding?.tagline);
        setEntityValue('description', entityDetails?.co_branding?.description);
      }
    }
  }, [entityId, setEntityValue, entityDetails]);


  const [selectedImage, setSelectedImage] = useState<string | undefined>('');

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setEntityValue('logo_url', file);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const onSubmitAdminForm = async (data: any) => {
    if (!data.phone_number.startsWith("+91")) {
      data.phone_number = `+91${data.phone_number}`;
    }
    setAdminLoading(true);
    const entityAdminFormRequest: AddEntityAdminRequest = {
      entityId: entityIdByResponse,
      entityAdminData: data
    };
    createEntityAdmin(entityAdminFormRequest, {
      onSuccess: () => {
        navigate('/entities');
      },
      onSettled: () => {
        setAdminLoading(false);
      },
    });
  };

  return (
    <div>
      <div className="mb-4">
        <div className="mb-4">
          <BackButton to="/entities" label="Back"/>
        </div>
        <h1 className="text-xl font-bold mb-4">
          {entityId ? 'Update Entity' : 'Create Entity'}
        </h1>
        <CustomBreadcrumb
          items={[
            {label: 'Entities', to: '/entities'},
            {label: entityId ? 'Update Entity' : 'Create Entity'},
          ]}
        />
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        {!entityId && (
          <Stepper currentStep={currentStep} steps={steps}/>
        )}

        {currentStep === 0 && (
          <form className='mb-8' onSubmit={handleSubmitEntity(onSubmitEntityForm)}>
            <div className="flex space-x-6 mb-4">
              <FormSelect
                className='w-1/2'
                disabled={loading || !!entityId}
                label="Business model"
                important={true}
                options={businessModalOptions.map((option) => ({value: option, label: option}))}
                register={registerEntity("business_model")}
                onChangeSelect={handleBusinessModelChange}
                error={entityErrors.business_model?.message}
              />
              <FormInput
                disabled={loading}
                className='w-1/2'
                label="Entity name"
                type='text'
                important={true}
                register={registerEntity("name")}
                error={entityErrors.name?.message}
                placeholder="Enter entity name"
              />
            </div>
            <div className="flex space-x-6 mb-4">
              <FormSelect
                className='w-1/2'
                disabled={loading || !!entityId}
                label="Entity Type"
                important={true}
                options={entityTypeOptions.map((option) => ({value: option, label: option}))}
                register={registerEntity("type")}
                error={entityErrors.type?.message}
              />
              <FormSelect
                className='w-1/2'
                disabled={loading}
                label="Region"
                important={true}
                options={regionOptions.map((option) => ({value: option, label: option}))}
                register={registerEntity("region")}
                error={entityErrors.region?.message}
              />
            </div>
            <div className="flex space-x-6 mb-4">
              <FormInput
                disabled={loading}
                className='w-1/2'
                label="Address"
                type='text'
                important={true}
                register={registerEntity("address")}
                error={entityErrors.address?.message}
                placeholder="Enter address"
              />

              <FormInput
                disabled={loading}
                className='w-1/2'
                label="City"
                type='text'
                important={true}
                register={registerEntity("city")}
                error={entityErrors.city?.message}
                placeholder="Enter city"
              />
            </div>
            <div className="flex space-x-6 mb-4">
              <FormInput
                disabled={loading}
                className='w-1/2'
                label="State"
                important={true}
                register={registerEntity("state")}
                error={entityErrors.state?.message}
                placeholder="Enter state"
              />

              <FormInput
                disabled={loading}
                className='w-1/2'
                label="Pincode"
                important={true}
                register={registerEntity("pincode")}
                error={entityErrors.pincode?.message}
                placeholder="Enter pincode"
              />
            </div>
            <div className="flex space-x-6 mb-4">
              <FormInput
                disabled={loading}
                className='w-1/2'
                label="Phone number"
                important={true}
                register={registerEntity("phone_number")}
                error={entityErrors.phone_number?.message}
                placeholder="Enter phone number"
              />

              <FormInput
                disabled={loading}
                className='w-1/2'
                label="Email"
                type='email'
                important={true}
                register={registerEntity("email")}
                error={entityErrors.email?.message}
                placeholder="Enter email address"
              />
            </div>

            {isCoBranding && (
              <div className={`mb-4`}>
                <h2 className={`font-bold text-base mb-4 border-b pb-4`}>Co-Branding Details</h2>
                <div className="flex space-x-6 mb-4">
                  <FormInput
                    disabled={loading || !!entityId}
                    className='w-1/2'
                    label="Tagline"
                    important={true}
                    register={registerEntity("tagline")}
                    error={entityErrors.tagline?.message}
                    placeholder="Enter tagline"
                  />

                  <FormTextarea
                    className={`w-1/2`}
                    disabled={loading || !!entityId}
                    label="Description"
                    important={true}
                    register={registerEntity('description')}
                    error={entityErrors.description?.message}
                    placeholder="Write text here ..."
                  />
                </div>

                <div className="flex space-x-6 mb-4">
                  <div className="w-1/2">
                    <Label htmlFor="logo-lable" className="mb-2 block">
                      Entity logo<em>*</em>
                    </Label>

                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0 relative">
                        {selectedImage ? (
                          <Avatar
                            img={selectedImage}
                            alt="Profile Avatar"
                            size="xl"
                            className="rounded-md border-2 border-gray-500 p-2"
                          />
                        ) : (
                          <div
                            className="flex items-center justify-center w-40 h-40 rounded-md border-2 border-gray-500 p-2"> {/* Adjust to match Avatar size */}
                            <MdOutlineImageNotSupported className="text-gray-500"
                                                        size={64}/>
                          </div>
                        )}
                      </div>

                      <div className="flex space-x-2">
                        <Label disabled={!!entityId} htmlFor="entity-logo"
                               className={`p-2 text-sm font-medium border rounded cursor-pointer ${
                                 !!entityId ? 'text-gray-400 border-gray-400 cursor-not-allowed' : 'text-blue-600 border-blue-600 hover:bg-blue-50'
                               }`}>
                          Upload entity icon
                        </Label>
                        <FileInput
                          {...registerEntity('logo_url')}
                          disabled={loading || !!entityId}
                          id="entity-logo"
                          className="hidden"
                          onChange={handleImageUpload}
                          aria-label="Entity logo"
                          accept="image/*"
                        />
                        <Button
                          disabled={!selectedImage || !!entityId}
                          color='gray'
                          size='sm'
                          type="button"
                          onClick={() => setSelectedImage('')}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                    {!selectedImage && (
                      <span className="text-red-600">Logo is required</span>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="flex space-x-8">
              <CustomLoaderButton
                size="lg"
                color="blue"
                type="submit"
                loading={loading}>
                {entityId ? 'Update Entity' : 'Next: Add Entity Admin'}
              </CustomLoaderButton>
            </div>
          </form>
        )}
        {currentStep === 1 && (
          <>
            {entityId && entityDetails?.entity_admin ? (
              <EntityAdminView entityAdmin={entityDetails?.entity_admin} title='Admin Details' isButton={true}/>
            ) : (
              <form onSubmit={handleSubmitEntityAdmin(onSubmitAdminForm)}>
                <div className='flex flex-col space-y-2 mb-6'>
                  <FormInput
                    className="w-full"
                    label="First name"
                    important={true}
                    register={registerEntityAdmin("first_name")}
                    error={EntityAdminErrors.first_name?.message}
                    placeholder="Enter first name"
                    disabled={adminLoading}
                  />
                  <FormInput
                    label="Last name"
                    important={true}
                    register={registerEntityAdmin("last_name")}
                    error={EntityAdminErrors.last_name?.message}
                    placeholder="Enter last name"
                    disabled={adminLoading}
                  />
                  <FormSelect
                    className='w-full'
                    label="Gender"
                    important={true}
                    options={genderOptions}
                    register={registerEntityAdmin("gender")}
                    disabled={adminLoading}
                    error={EntityAdminErrors.gender?.message}
                  />
                  <FormInput
                    label="Email"
                    important={true}
                    register={registerEntityAdmin("email")}
                    error={EntityAdminErrors.email?.message}
                    placeholder="Enter email address"
                    disabled={adminLoading}
                  />
                  <FormInput
                    label="Phone number"
                    important={true}
                    register={registerEntityAdmin("phone_number")}
                    error={EntityAdminErrors.phone_number?.message}
                    placeholder="Enter phone number"
                    disabled={adminLoading}
                  />
                </div>
                <div className="flex justify-start space-x-2">
                  <CustomLoaderButton
                    size="lg"
                    color="blue"
                    type="submit"
                    loading={adminLoading}>
                    Add Entity Admin
                  </CustomLoaderButton>
                </div>
              </form>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CreateEntity;
