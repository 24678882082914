import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {
  completeIndividualSessionApi,
  createIndividualActionPlanApi,
  fetchCounsellorIndividualSessionsApi
} from "./counsellorIndividualSessionsApi";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";
import {
  CounsellorIndividualSessionInterface
} from "../../../core/interface/counsellor-individual-sessions/CounsellorIndividualSessionInterface";

export const useGetCounsellorIndividualSessionsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<CounsellorIndividualSessionInterface[]>>({
    queryKey: ['counsellorIndividualSessions', {page, pageSize}],
    queryFn: () => fetchCounsellorIndividualSessionsApi(page, pageSize),
    retry: false,
    staleTime: 0,
  });
};

export const useCreateIndividualActionPlanApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({planId, stageId, data}:{planId: any, stageId: any, data: any}) => createIndividualActionPlanApi(planId, stageId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['counsellorIndividualSessions']});
      const message = 'Individual action plan created successfully!';
      showToastMessage({ type: ToastType.Success, message });
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['webinars']});
    }
  })
}

export const useCompleteIndividualSessionApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({data}: { data: any }) => completeIndividualSessionApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['counsellorIndividualSessions']});
      const message = 'Individual session completed successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['counsellorIndividualSessions']});
    }
  });
}
