import {CustomResponse} from "../../../core/interface/CustomResponse";
import axios from "axios";
import {
  CounsellorEntitySessionInterface
} from "../../../core/interface/counsellor-entity-session/CounsellorEntitySessionInterface";

// Fetch counsellor entity session
export const fetchCounsellorEntitySessionsApi = async (page: number, pageSize: number): Promise<CustomResponse<CounsellorEntitySessionInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<CounsellorEntitySessionInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/counsellor/entity-sessions`,
    {params: queryParams}
  );
  return response.data;
};

// Complete counsellor entity session
export const completeCounsellorEntitySessionApi = async (planId: any, stageId: any, data: any) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}/stage/${stageId}/complete-entity-session`, data
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}
