import React, {useEffect} from "react";
import {useFetchAvailableCounsellorHostsApi} from "../services/useCounsellorHostApi";
import {Badge, Spinner, Table} from "flowbite-react";
import {dateAndTimeFormatter} from "../../../utils/dateAndTimeFormatter";
import {CounsellorHostInterface} from "../../../../core/interface/host/CounsellorHostInterface";
import {AvailableCounsellorHostRequest} from "../../../../core/interface/host/AvailableCounsellorHostRequest";

interface AvailableCounsellorHostProps {
  stageId?: string;
  planId?: string;
  timePeriods?: string;
  sessionDuration?: number;
  selectedHost: CounsellorHostInterface | null;
  onHostSelect: (host: CounsellorHostInterface) => void;
  onHostListCount?: (count: number) => void;
}

const AvailableCounsellorHost: React.FC<AvailableCounsellorHostProps> = (props) => {

  const availableCounsellorHostRequest: AvailableCounsellorHostRequest = {
    currentPage: 1,
    pageSize: 30,
    stageId: props.stageId,
    planId: props.planId,
    timePeriods: props.timePeriods,
    sessionDuration: props.sessionDuration,
  };

  const {
    data: counsellorHostListData,
    isLoading: isLoadingHostList
  } = useFetchAvailableCounsellorHostsApi(availableCounsellorHostRequest);
  const availableCounsellorHostList: CounsellorHostInterface[] = counsellorHostListData?.data?.items || [];

  useEffect(() => {
    if (props.onHostListCount) {
      props.onHostListCount(availableCounsellorHostList.length);
    }
  }, [availableCounsellorHostList.length, props, props.onHostListCount]);

  const handleRowClick = (host: CounsellorHostInterface) => {
    props.onHostSelect(host);
  };

  return (
    <div>
      {isLoadingHostList && (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      )}
      {availableCounsellorHostList && availableCounsellorHostList.length === 0 ? (
        <div className="flex justify-center items-center py-5">
          <p className="text-gray-500">No counsellor hosts available</p>
        </div>
      ) : (
        <>
          <h3 className="text-lg font-bold text-gray-900 mb-4">
            Available counsellor -
            <span className="ml-2 text-base font-medium text-blue-700">
              On {dateAndTimeFormatter(props.timePeriods, 'MMMM DD, YYYY, h:mm a')}
            </span>
          </h3>
          <Table className="w-full mb-4">
            <Table.Head>
              <Table.HeadCell>Host Name</Table.HeadCell>
              <Table.HeadCell>Host Role</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {availableCounsellorHostList.map((host) => (
                <Table.Row
                  key={host.name}
                  className={`cursor-pointer hover:bg-gray-100 ${props.selectedHost?.name === host.name ? "bg-blue-50" : ""}`}
                  onClick={() => handleRowClick(host)}
                >
                  <Table.Cell>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="host"
                        checked={props.selectedHost?.name === host.name}
                        onChange={() => handleRowClick(host)}
                        className="cursor-pointer"
                      />
                      <span>{host.name}</span>
                    </label>
                  </Table.Cell>
                  <Table.Cell>{(host.role || host.role.length > 0) ? host.role : <div className="flex"><Badge color="red">Host Predefined</Badge></div>}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  );
};

export default AvailableCounsellorHost;
