import React, {useState} from "react";
import ProfileField from "../../../../shared/components/profile-field/profileField";
import {
  CounsellorStageSessionProps
} from "../../../../core/interface/counsellor-individual-sessions/counsellor-session/CounsellorStageSessionProps";
import {dateAndTimeFormatter} from "../../../../shared/utils/dateAndTimeFormatter";
import {FaCheckCircle} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import CustomLoaderButton from "../../../../shared/components/custom-buttons/customLoaderButton";
import {
  useCompleteIndividualSessionApi
} from "../../../counsellor-individual-sessions/services/useCounsellorIndividualSessionsApi";
import TimeRange from "../../../../shared/components/date-and-time/timeRange";
import {
  useCompleteCounsellorEntitySessionApi
} from "../../../counsellor-entity-session/services/useCounsellorEntitySessionApi";
import {StageState} from "../../../../core/enums/stage/StageState";

const SessionStageDetails: React.FC<CounsellorStageSessionProps> = (props) => {

  const navigate = useNavigate();
  const currentDate = new Date();

  const sessionDateString = dateAndTimeFormatter(props.datetime, 'DDD MMM YYYY, h:mm a');
  const sessionDate = sessionDateString ? new Date(sessionDateString) : currentDate;
  const isButtonEnabled = sessionDate <= currentDate;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {mutate: completeIndividualSession} = useCompleteIndividualSessionApi();
  const {mutate: completeCounsellorEntitySession} = useCompleteCounsellorEntitySessionApi();

  const handleCompleteSession = () => {
    setIsLoading(true);

    const commonData = {
      customer_plan_id: props?.planId,
      customer_plan_stage_id: props?.stageId,
    };

    const apiCall =
      props.sessionType === 'EntitySession'
        ? () =>
          completeCounsellorEntitySession(
            {
              planId: props.planId,
              stageId: props.stageId,
              data: { stage_type: props.stageType },
            },
            {
              onSuccess: () => navigate(props?.navigateUrl),
              onSettled: () => setIsLoading(false),
            }
          )
        : () =>
          completeIndividualSession(
            { data: commonData },
            {
              onSuccess: () => navigate(props?.navigateUrl),
              onSettled: () => setIsLoading(false),
            }
          );

    apiCall();
  };

  return (
    <div>
      <h2 className={`text-lg font-bold mb-4`}>
        Session
      </h2>
      <div className={`bg-gray-50 p-8 rounded-md shadow-md mb-4`}>
        <h4 className={`text-base leading-tight font-semibold text-blue-700 mb-5`}>
          {props?.sessionName}
        </h4>
        <ProfileField label={`Date`} value={dateAndTimeFormatter(props?.datetime, 'MMMM DD, YYYY')}/>
        <ProfileField label={`Time`}
                      value={<TimeRange startTime={props?.datetime} duration={props?.durationInMins}/>}
        />
        <ProfileField label={`Mode`} value={props?.mode}/>
        <ProfileField label={`Host Name`} value={props?.hostName}/>
        <ProfileField label={`Meeting Link`} value={props?.meetingLinkHost} isLink={true}/>
      </div>
      {props.stageStatus !== StageState.COMPLETED && (
        <CustomLoaderButton loading={isLoading} color="blue" className={`flex items-center`} disabled={!isButtonEnabled}
                            onClick={handleCompleteSession}>
          {isButtonEnabled && <FaCheckCircle size={18} className={`mr-1`}/>}
          Mark as complete
        </CustomLoaderButton>
      )}
    </div>
  );
};

export default SessionStageDetails;
