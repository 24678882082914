import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {BlogInterface} from "../../../core/interface/blog/BlogInterface";
import {createBlogApi, deleteBlogApi, fetchBlogDetailsApi, fetchBlogsApi, updateBlogApi} from "./blogsApi";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetBlogsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<BlogInterface[]>>({
    queryKey: ['blogs', {page, pageSize}],
    queryFn: () => fetchBlogsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchBlogDetailsApi = (blogId: any) => {
  return useQuery<DetailsCustomDataResponse<BlogInterface>>({
    queryKey: ['blog', {blogId}],
    queryFn: () => fetchBlogDetailsApi(blogId),
    enabled: !!blogId
  });
}

export const useCreateBlogApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (data: any) => createBlogApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['blogs']});
      const message = 'Blog created successfully!';
      showToastMessage({ type: ToastType.Success, message });
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['Blogs']});
    }
  })
}

export const useUpdateBlogApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: ({ blogId, data }: { blogId: string; data: any }) => updateBlogApi(blogId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['blogs'] });
      const message = 'Blog updated successfully!';
      showToastMessage({ type: ToastType.Success, message });
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['blogs']});
    }
  });
};

export const useDeleteBlogApi = () => {
  const { showToastMessage } = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (vlogId: string) => deleteBlogApi(vlogId),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
      const message = "Blog deleted successfully!";
      showToastMessage({ type: ToastType.Success, message });
    },
    onError: (error: any) => {
      const message = error.detail || "Something went wrong.";
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
    },
  });
};
