import axios from "axios";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {StaffGroupSessionInterface} from "../../../core/interface/staff-group-session/StaffGroupSessionInterface";
import {GroupCustomerInterface} from "../../../core/interface/staff-group-session/GroupCustomerInterface";

// Fetch group sessions
export const fetchGroupSessionsApi = async (page: number, pageSize: number): Promise<CustomResponse<StaffGroupSessionInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<StaffGroupSessionInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/group-sessions`,
    {params: queryParams}
  );
  return response.data;
};

// Fetch customers group by plan id
export const fetchCustomersGroupApi = async (planId: any, page: number, pageSize: number): Promise<CustomResponse<GroupCustomerInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<GroupCustomerInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}/group-customers`,
    {params: queryParams}
  );
  return response.data
}

// Create group session
export const createGroupSessionApi = async (stageId: any, data: any)=> {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/staffs/stage/${stageId}/create-group`, data);
    return response.data;
  }  catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};
