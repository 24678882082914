import React, {useState} from "react";
import {FaUserCircle} from "react-icons/fa";
import {HiUserGroup} from "react-icons/hi";
import {
  useGetGroupApprovalRequestsApi,
  useGetIndividualApprovalRequestsApi
} from "../services/useCounsellorApprovalRequestApi";
import {Button, Pagination, Spinner, Table} from "flowbite-react";
import {Link} from "react-router-dom";
import {
  CounsellorIndividualApprovalRequestInterface
} from "../../../core/interface/counsellor-approval/CounsellorIndividualApprovalRequestInterface";
import {
  CounsellorGroupApprovalRequestInterface
} from "../../../core/interface/counsellor-approval/CounsellorGroupApprovalRequestInterface";

const CounsellorApprovalRequests: React.FC = () => {
  const pageSize = 10;
  const [activeTab, setActiveTab] = useState(0);
  const [individualCurrentPage, setIndividualCurrentPage] = useState<number>(1);
  const [groupCurrentPage, setGroupCurrentPage] = useState<number>(1);

  const { data: individualApprovalRequestsList, isLoading: isLoadingIndividual } = useGetIndividualApprovalRequestsApi(individualCurrentPage, pageSize);
  const individualApprovalRequests: CounsellorIndividualApprovalRequestInterface[] = individualApprovalRequestsList?.data.items || [];
  const individualTotalCount = individualApprovalRequestsList?.data.total || 0;
  const individualTotalPages = Math.ceil(individualTotalCount / pageSize);

  const { data: groupApprovalRequestsList, isLoading: isLoadingGroup } = useGetGroupApprovalRequestsApi(groupCurrentPage, pageSize);
  const groupApprovalRequests: CounsellorGroupApprovalRequestInterface[] = groupApprovalRequestsList?.data.items || [];
  const groupTotalCount = groupApprovalRequestsList?.data.total || 0;
  const groupTotalPages = Math.ceil(groupTotalCount / pageSize);

  const handleIndividualPageChange = (page: number) => {
    if (page > 0 && page <= individualTotalPages) {
      setIndividualCurrentPage(page);
    }
  };

  const handleGroupPageChange = (page: number) => {
    if (page > 0 && page <= groupTotalPages) {
      setGroupCurrentPage(page);
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-5">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">Approval Requests</h1>
      </div>

      <div className="flex shadow-md rounded-lg overflow-hidden mb-4">
        <div
          onClick={() => setActiveTab(0)}
          className={`flex-1 py-3 flex justify-center items-center cursor-pointer border-r ${
            activeTab === 0
              ? "bg-white text-gray-800 font-semibold"
              : "bg-gray-50 text-gray-500 hover:text-gray-800"
          }`}
        >
          <FaUserCircle size={20} className="mr-2"/>Individual Approval Requests
        </div>
        <div
          onClick={() => setActiveTab(1)}
          className={`flex-1 py-3 flex justify-center items-center cursor-pointer ${
            activeTab === 1
              ? "bg-white text-gray-800 font-semibold"
              : "bg-gray-50 text-gray-500 hover:text-gray-800"
          }`}
        >
          <HiUserGroup size={20} className="mr-2"/> Group Approval Requests
        </div>
      </div>

      {activeTab === 0 ? (
        <div className="relative shadow-md sm:rounded-lg">
          <Table striped>
            <Table.Head className="border-b border-gray-200">
              <Table.HeadCell>Plan Name</Table.HeadCell>
              <Table.HeadCell>Stage Name</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>

            {isLoadingIndividual ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={3} className="py-5 text-center">
                    <Spinner size="lg"/>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : individualTotalCount === 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={3} className="py-5 text-center text-gray-500">
                    No individual approval request records found.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body className="divide-y">
                {individualApprovalRequests.map((individualApprovalRequest) => (
                  <Table.Row
                    key={individualApprovalRequest.stage_id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {individualApprovalRequest.customer_plan_name}
                    </Table.Cell>
                    <Table.Cell>{individualApprovalRequest.stage_name}</Table.Cell>
                    <Table.Cell className="flex items-center space-x-2">
                      <Link to={`individual-approval-request-details/${individualApprovalRequest?.stage_id}`}
                      state={{
                        planId: individualApprovalRequest?.customer_plan_id
                      }}>
                        <Button color="blue" size="xs">
                          Review
                        </Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>

          {individualTotalCount > 0 && (
            <div className="flex justify-between items-center p-6 border-t">
              <p className="text-gray-500">
                Showing{" "}
                <span className="font-semibold text-black">
                    {pageSize * (individualCurrentPage - 1) + 1}-
                  {Math.min(pageSize * individualCurrentPage, individualTotalCount)}
                  </span>{" "}
                of <span className="font-semibold text-black">{individualTotalCount}</span>
              </p>
              <Pagination
                currentPage={individualCurrentPage}
                totalPages={individualTotalPages}
                onPageChange={handleIndividualPageChange}
                showIcons={true}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="relative shadow-md sm:rounded-lg">
          <Table striped>
            <Table.Head className="border-b border-gray-200">
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Report Type</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>

            {isLoadingGroup ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={3} className="py-5 text-center">
                    <Spinner size="lg"/>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : groupTotalCount === 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={3} className="py-5 text-center text-gray-500">
                    No group assign approvers records found.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body className="divide-y">
                {groupApprovalRequests.map((groupApprovalRequest) => (
                  <Table.Row
                    key={groupApprovalRequest.customer_group_session_id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {groupApprovalRequest.entity_plan_name}
                    </Table.Cell>
                    <Table.Cell>{groupApprovalRequest.group_name}</Table.Cell>
                    <Table.Cell className="flex items-center space-x-2">
                      <Link to={`group-approval-request-details/${groupApprovalRequest?.customer_group_session_id}`}>
                        <Button color="blue" size="xs">
                          Review
                        </Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>

          {groupTotalCount > 0 && (
            <div className="flex justify-between items-center p-6 border-t">
              <p className="text-gray-500">
                Showing{" "}
                <span className="font-semibold text-black">
                    {pageSize * (groupCurrentPage - 1) + 1}-
                  {Math.min(pageSize * groupCurrentPage, groupTotalCount)}
                  </span>{" "}
                of <span className="font-semibold text-black">{groupTotalCount}</span>
              </p>
              <Pagination
                currentPage={groupCurrentPage}
                totalPages={groupTotalPages}
                onPageChange={handleGroupPageChange}
                showIcons={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CounsellorApprovalRequests;
