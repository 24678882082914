import React, {useState} from "react";
import {CustomerCsvFileUploadProps} from "../../../../../core/interface/customer/CustomerCsvFileUploadProps";
import {Alert, FileInput, Label} from "flowbite-react";
import {HiExclamationCircle, HiOutlineCloudUpload} from "react-icons/hi";


const CustomerCsvFileUpload: React.FC<CustomerCsvFileUploadProps> = (props: CustomerCsvFileUploadProps) => {
  
  const [error, setError] = useState<string | null>(null);
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > props.maxFileSize) {
        setError(`File size exceeds ${props.maxFileSize / 1024 / 1024}MB limit.`);
        props.onFileChange(null);
      } else {
        setError(null);
        props.onFileChange(file);
      }
    }
  };
  
  return (
    <div>
      <div className="flex justify-between items-center px-2 mb-1">
        {props.label && <Label htmlFor="file" value={props.label}/>}
        {props.sampleCsvUrl && (
          <a href={props.sampleCsvUrl} download className="text-blue-600 flex items-center">
            <HiOutlineCloudUpload size={20} className="mr-1"/>
            Download Sample CSV
          </a>
        )}
      </div>

      <FileInput
        onChange={handleFileChange}
        className="w-full"
        accept=".csv"
      />
      
      {error && (
        <div className="mt-2 text-sm">
          <Alert color="failure" icon={HiExclamationCircle}>
            {error}
          </Alert>
        </div>
      )}
      
      {props.errorMessage && !error && (
        <div className="mt-2 text-sm">
          <Alert color="failure" icon={HiExclamationCircle}>
            {props.errorMessage}
          </Alert>
        </div>
      )}
    </div>
  );
}

export default CustomerCsvFileUpload;
