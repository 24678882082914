import axios from "axios";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {
  StaffIndividualSessionsInterface
} from "../../../core/interface/staff-individual-session/StaffIndividualSessionsInterface";

// Fetch staff individual sessions
export const fetchStaffIndividualSessionsApi = async (page: number, pageSize: number): Promise<CustomResponse<StaffIndividualSessionsInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<StaffIndividualSessionsInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/individual-sessions`,
    {params: queryParams}
  );
  return response.data;
};

// Create session
export const createSessionApi = async (planId: any, stageId: any, data: any)=> {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}/stage/${stageId}/create-session`, data);
    return response.data;
  }  catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};
