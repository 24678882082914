import React, {useMemo, useState} from "react";
import {Button, Pagination, Spinner, Table} from "flowbite-react";
import {Link} from "react-router-dom";
import {useGetStaffIndividualSessionsApi} from "../services/useStaffIndividualSessionsApi";
import {
  StaffIndividualSessionsInterface
} from "../../../core/interface/staff-individual-session/StaffIndividualSessionsInterface";

const StaffIndividualSessions: React.FC = () => {

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, isLoading} = useGetStaffIndividualSessionsApi(currentPage, pageSize);
  const individualSessions: StaffIndividualSessionsInterface[] = useMemo(() => data?.data.items || [], [data]);
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-5">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">Individual Sessions</h1>
        <p className={`text-sm text-gray-500`}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin accumsan sed neque non consequat. Curabitur
          consectetur turpis tortor, in tincidunt mauris iaculis et. Suspendisse condimentum et nunc sed dapibus.
        </p>
      </div>

      <div className={`overflow-x-auto relative shadow-md sm:rounded-lg`}>
        <div className="overflow-x-auto">
          <Table striped>
            <Table.Head className='border-b border-gray-200'>
              <Table.HeadCell className={`text-gray-500`}>Customer Name</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Business Vertical</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Plan Name</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Session Name</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>actions</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {isLoading ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <div className="flex justify-center items-center py-5">
                      <Spinner size="lg"/>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : totalCount === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <div className="flex justify-center items-center py-5">
                      <div className="tet-center text-gray-500">No staff individual session records found.</div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : (
                <>
                  {individualSessions.map((individualSession) => (
                    <Table.Row key={individualSession.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {individualSession.customer_name}
                      </Table.Cell>
                      <Table.Cell>
                        {individualSession.business_vertical}
                      </Table.Cell>
                      <Table.Cell className="flex gap-2">
                        {individualSession.customer_plan_name}
                      </Table.Cell>
                      <Table.Cell>
                        {individualSession.session_name}
                      </Table.Cell>
                      <Table.Cell className={`flex items-center space-x-2`}>
                        <Link to={`create-session/${individualSession.id}`}>
                          <Button color="blue" size="xs">
                            Create Session
                          </Button>
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
        </div>
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffIndividualSessions;
