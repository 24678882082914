import React from 'react';
import SidebarMenu from "../sidebar-menu/sidebarMenu";
import TopNavbar from "../headers/topNavbar";
import {LayoutProps} from "../../../core/interface/LayoutProps";

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="sticky top-0 z-50 bg-white shadow-md">
        <TopNavbar/>
      </div>
      
      <div className="flex flex-1">
        <div className="sticky top-[4rem] h-[calc(100vh-4rem)]">
          <SidebarMenu/>
        </div>
        <main className="flex-1 bg-gray-50 overflow-auto p-6">
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
