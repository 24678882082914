import {Badge, Spinner} from 'flowbite-react';
import {EntityPlanStatus} from "../../../core/enums/entityPlanStatus";

const StatusBadge = (status: EntityPlanStatus) => {
  switch (status) {
    case EntityPlanStatus.ACTIVE:
      return <Badge color="green">Active</Badge>;
    case EntityPlanStatus.EXPIRED:
      return <Badge color="red">Expired</Badge>;
    case EntityPlanStatus.PAYMENT_COMPLETED:
      return <Badge color="yellow">Payment Completed</Badge>;
    case EntityPlanStatus.SETUP_PLAN_COMPLETED:
      return <Badge color="yellow">Entity Plan Payment Pending</Badge>;
    case EntityPlanStatus.SETUP_ADD_PLAN_COMPLETED:
      return <Badge color="yellow">Customers Setup Pending</Badge>;
    case EntityPlanStatus.SETUP_ADD_CUSTOMER_INPROGRESS:
      return <Badge color="yellow">
        <Spinner size="sm" className='mr-2' color="warning" aria-label="Customers Setup Inprogress" />Customers Setup Inprogress
      </Badge>;
    default:
      return;
  }
};

export default StatusBadge;
