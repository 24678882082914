import {useQuery} from "@tanstack/react-query";
import {CustomResponse} from "../../../../core/interface/CustomResponse";
import {fetchAvailableCounsellorHostsApi} from "./counsellorHostApi";
import {AvailableCounsellorHostRequest} from "../../../../core/interface/host/AvailableCounsellorHostRequest";
import {CounsellorHostInterface} from "../../../../core/interface/host/CounsellorHostInterface";

export const useFetchAvailableCounsellorHostsApi = (availableCounsellorHostRequest: AvailableCounsellorHostRequest) => {
  return useQuery<CustomResponse<CounsellorHostInterface[]>>({
    queryKey: ['availableCounsellorHosts', {availableCounsellorHostRequest: availableCounsellorHostRequest}],
    queryFn: () => fetchAvailableCounsellorHostsApi(availableCounsellorHostRequest),
    retry: false,
    staleTime: 300000,
    enabled: !!availableCounsellorHostRequest.timePeriods && !!availableCounsellorHostRequest.planId && !!availableCounsellorHostRequest.stageId,
  });
};
