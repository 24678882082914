import React, {useState} from "react";
import {Checkbox, Pagination, Spinner, Table} from "flowbite-react";
import {
  CustomersWithCheckBoxProps
} from "../../../../core/interface/staff-group-session/props/CustomersWithCheckBoxProps";
import {useGetCustomersGroupApi} from "../../services/useStaffGroupSessionsApi";
import {GroupCustomerInterface} from "../../../../core/interface/staff-group-session/GroupCustomerInterface";

const CustomersWithCheckBox: React.FC<CustomersWithCheckBoxProps> = (props) => {

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, isLoading} = useGetCustomersGroupApi(props.planId, currentPage, pageSize);
  const groupCustomers: GroupCustomerInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const isCustomerSelected = (customerId: string) =>
    props.selectedCustomers.some((customer) => customer.customer_id === customerId);

  // Handle individual checkbox change
  const handleCheckboxChange = (customer: GroupCustomerInterface) => {
    const updatedCustomers = isCustomerSelected(customer.customer_id)
      ? props.selectedCustomers.filter((selected) => selected.customer_id !== customer.customer_id) // Remove customer if already selected
      : [...props.selectedCustomers, customer];

    props.onCustomerSelect(updatedCustomers);
  };

  const handleSelectAllChange = () => {
    if (props.selectedCustomers.length === groupCustomers.length) {
      props.onCustomerSelect([]);
    } else {
      props.onCustomerSelect(groupCustomers);
    }
  };

  const isAllSelected = props.selectedCustomers.length === groupCustomers.length;

  return (
    <div>
      <h2 className="text-lg font-bold mb-4">Select Customers</h2>
      <div className="overflow-x-auto">
        <Table striped>
          <Table.Head className="border-b border-gray-200">
            <Table.HeadCell>
              <Checkbox checked={isAllSelected} onChange={handleSelectAllChange}/>
            </Table.HeadCell>
            <Table.HeadCell>Customer Name</Table.HeadCell>
            <Table.HeadCell>Stream</Table.HeadCell>
          </Table.Head>
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={2}>
                  <div className="flex justify-center items-center py-5">
                    <Spinner size="lg"/>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : totalCount === 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={2}>
                  <div className="flex justify-center items-center py-5">
                    <div className="tet-center text-gray-500">No group customer records found.</div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body className="divide-y">
              {groupCustomers.map((customer, index) => (
                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>
                    <Checkbox
                      checked={isCustomerSelected(customer.customer_id)}
                      onChange={() => handleCheckboxChange(customer)}
                    />
                  </Table.Cell>
                  <Table.Cell>{customer.customer_name}</Table.Cell>
                  <Table.Cell>{customer.stream ? customer.stream : '-'}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            )}
        </Table>
      </div>
      {totalCount > 0 && (
        <div className="flex justify-between items-center p-6 border-t">
          <p className="text-gray-500">
            Showing <span
            className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
            className="font-semibold text-black">{totalCount}</span>
          </p>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            showIcons={true}
          />
        </div>
      )}
    </div>
  );
};

export default CustomersWithCheckBox;
