import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextarea from "../../../../shared/components/form-wrapper/FormTextarea";
import FormChartTable from "../../../../shared/components/form-wrapper/formChartTable";
import {Button} from "flowbite-react";
import {RiDeleteBin5Line} from "react-icons/ri";
import ResourceCardList from "./resourceCardList/resourceCardList";
import {useNavigate} from "react-router-dom";
import {ResourceResponseProps} from "../../../../core/interface/counsellor-individual-sessions/ResourceRequestProps";
import {ActionPlanProps} from "../../../../core/interface/counsellor-individual-sessions/ActionPlanProps";
import {useCreateIndividualActionPlanApi} from "../../services/useCounsellorIndividualSessionsApi";
import CustomLoaderButton from "../../../../shared/components/custom-buttons/customLoaderButton";
import ActionPlanDbContent
  from "../../../../shared/components/action-plan-wrapper/fetch-db-content/actionPlanDbContent";
import SubjectInterestDbContent
  from "../../../../shared/components/action-plan-wrapper/fetch-db-content/subjectInterestDbContent";
import {
  SelectedSession
} from "../../../../core/interface/counsellor-individual-sessions/action-plan-db-content/SelectedSession";

// Validation schema without required fields
const schema = yup.object().shape({
  feedback: yup.string().min(10, "Feedback must be at least 10 characters").required("Feedback is required"),
  description: yup.string().required("Description is required"),
  chartData: yup.array().required("Chart data is required"),
  learning_style_strategies_title: yup.string().required("Learning Style Strategies title are required"),
  learning_style_strategies: yup.string().required("Learning Style Strategies are required"),
  subjects_interest: yup.array().min(1, "At least one subject of interest is required").required("Subjects of interest is required"),
  areas_of_improvement: yup.string().required("Areas of improvement is required"),
  customer_suggestion: yup.string().required("Customer suggestion is required"),
  parent_suggestion: yup.string().required("Parent suggestion is required"),
  additional_info: yup.string(),
  resource: yup.array().min(1, "At least one resource is required").required("Resources are required") // Set resource to be required
});

const IndividualActionPlan8th9th: React.FC<ActionPlanProps> = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedChartData, setSelectedChartData] = useState<any>(null);
  const [isShowActionPlanDbContent, setIsShowActionPlanDbContent] = useState(false);
  const [isShowSubjectsInterest, setIsShowSubjectsInterest] = useState(false);
  const [key, setKey] = useState<any>(null);
  const [selectedActionPlanDbContent, setSelectedActionPlanDbContent] = useState<any>(null);
  const [selectedSubjectsInterest, setSelectedSubjectsInterest] = useState<any>(null);

  const {mutate: createActionPlan} = useCreateIndividualActionPlanApi();

  const handleActionPlanDbContent = (key: string) => {
    setIsShowActionPlanDbContent(true);
    setKey(key);
  }

  const handleSubjectInterestDbContent = (key: string) => {
    setIsShowSubjectsInterest(true);
    setKey(key);
  }

  const deleteActionPlan = (title: string) => {
    if (selectedActionPlanDbContent.title === title) {
      setSelectedActionPlanDbContent(null);
    }
  };

  const handleAddActionPlan = (data: SelectedSession) => {
    setKey(null);
    setValue("learning_style_strategies", data.strategy);
    setValue("learning_style_strategies_title", data.title);
    setSelectedActionPlanDbContent(data);
    setIsShowActionPlanDbContent(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleAddSubjectInterest = (data: string[]) => {
    setKey(null);
    setValue("subjects_interest", data);
    setSelectedSubjectsInterest(data);
    setIsShowSubjectsInterest(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    const actionPlanData = {
      feedback: data.feedback,
      add_on_desc: {
        study_chart: {
          desc: data.description,
          charts: data.chartData
        },
        learning_style_strategies: {
          title: data.learning_style_strategies_title,
          strategies: data.learning_style_strategies
        }
      },
      subjects_interest: data.subjects_interest,
      areas_of_improvement: data.areas_of_improvement,
      suggestions: {
        customer_suggestion: data.customer_suggestion,
        parent_suggestion: data.parent_suggestion,
        additional_info: data.additional_info
      },
      resource: data.resource,
    }
    createActionPlan({planId: props.planId, stageId: props.stageId, data: actionPlanData}, {
      onSuccess: () => navigate("/counsellor-individual-sessions"),
      onSettled: () => setLoading(false)
    })
  };

  return (
    <>
      {!isShowActionPlanDbContent && !isShowSubjectsInterest && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className={`text-2xl leading-tight font-bold mb-3`}>Action Plan (8th, 9th)</h2>
          <div className={`border-b pb-5 mb-5`}>
            <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>1. Feedback</h3>
            <FormTextarea
              className="w-full mb-4"
              subClassName="h-32"
              register={register("feedback")}
              error={errors.feedback?.message}
              placeholder="Write text here ..."
            />
          </div>

          <div className={`border-b pb-5 mb-5`}>
            <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-2`}>2. Add on description</h3>
            <h4 className={`text-lg text-blue-800 font-semibold mb-4`}>Study chart template</h4>
            <FormTextarea
              className="w-full mb-4"
              subClassName="h-32"
              label="Description"
              register={register("description")}
              error={errors.description?.message}
              placeholder="Write text here ..."
            />
            <div className="mb-4">
              <FormChartTable
                onChange={(data: any[]) => {
                  setValue("chartData", data);
                  setSelectedChartData(data);
                }}
                selectedData={selectedChartData}
              />
              {errors.chartData && <span className="text-red-600">{errors.chartData.message}</span>}
            </div>

            <div>
              <div className="flex justify-between items-center mb-4">
                <h4 className={`text-lg text-blue-800 font-semibold mb-4`}>Learning Style Strategies</h4>
                <Button color="blue" onClick={() => handleActionPlanDbContent('learning_style_strategies')}>
                  Fetch Data From DB
                </Button>
              </div>

              {selectedActionPlanDbContent ? (
                <div className={`border border-gray-500 rounded-md p-4`}>
                  <h4 className="text-base font-semibold mb-4">{selectedActionPlanDbContent.title}</h4>
                  <div className="border border-gray-300 rounded-md p-4">
                    <div className="flex justify-between items-center mb-4">
                  <span className="border border-green-600 p-2 rounded-full text-base font-semibold text-green-600">
                    {selectedActionPlanDbContent.index}
                  </span>
                      <Button onClick={() => deleteActionPlan(selectedActionPlanDbContent.title)}
                              color="none"
                              className="flex items-center border border-gray-400 rounded-full w-8 h-8 p-5">
                        <RiDeleteBin5Line color="red" size="20"/>
                      </Button>
                    </div>
                    <p className="border border-gray-300 p-4 text-sm font-semibold text-gray-600 rounded-md">
                      {selectedActionPlanDbContent.strategy}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="text-center text-gray-500 mt-4">
                  No Learning Style Strategies found. Please click "Fetch Data From DB" to load data.
                </div>
              )}
              {errors.learning_style_strategies &&
                  <span className="text-red-600">{errors.learning_style_strategies.message}</span>}
            </div>
          </div>

          <div className={`border-b pb-5 mb-5`}>
            <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>
              3. Subject Interest
            </h3>
            <div className="flex justify-between items-center mb-4">
              <h4 className={`text-lg text-blue-800 font-semibold mb-4`}>
                Subject by Interest
              </h4>
              <Button onClick={() => handleSubjectInterestDbContent('subjects_interest')} color="blue">
                Fetch Data From DB
              </Button>
            </div>
            {Array.isArray(selectedSubjectsInterest) && selectedSubjectsInterest.length > 0 ? (
              <div className="grid grid-cols-2 gap-4">
                {selectedSubjectsInterest.map((subject, index) => (
                  <div key={index} className="border rounded-md p-4 bg-gray-100 text-gray-800">
                    {subject}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center text-gray-500 mb-4">
                No Subjects of Interest found. Please click "Fetch Data From DB" to load data.
              </div>
            )}
            {errors.subjects_interest && <span className="text-red-600">{errors.subjects_interest.message}</span>}
          </div>

          <div className={`border-b pb-5 mb-5`}>
            <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>4. Areas of improvement</h3>
            <FormTextarea
              className="w-full mb-4"
              subClassName="h-32"
              register={register("areas_of_improvement")}
              error={errors.feedback?.message}
              placeholder="Write text here ..."
            />
          </div>

          <div className={`border-b pb-5 mb-5`}>
            <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>5. Suggestions</h3>
            <FormTextarea
              className="w-full mb-4"
              subClassName="h-32"
              label="Suggestions for student"
              register={register("customer_suggestion")}
              error={errors.customer_suggestion?.message}
              placeholder="Write text here ..."
            />
            <FormTextarea
              className="w-full mb-4"
              subClassName="h-32"
              label="Suggestions for parent"
              register={register("parent_suggestion")}
              error={errors.parent_suggestion?.message}
              placeholder="Write text here ..."
            />
            <FormTextarea
              className="w-full mb-4"
              subClassName="h-32"
              label="Additional information (Optional)"
              register={register("additional_info")}
              placeholder="Write text here ..."
            />
          </div>

          <div>
            <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>5. Suggestions</h3>
            <ResourceCardList onResourcesChange={(data: ResourceResponseProps[]) => setValue('resource', data)}/>
            {errors.resource && <span className="text-red-600">{errors.resource.message}</span>}
          </div>

          <CustomLoaderButton loading={loading} color='blue' size='lg' type='submit'>
            Submit
          </CustomLoaderButton>
        </form>
      )}
      {isShowActionPlanDbContent && (
        <ActionPlanDbContent
          keyProps={key}
          selectedActionPlanDbContent={selectedActionPlanDbContent}
          onAdd={(data) => {
            handleAddActionPlan(data);
          }}
        />
      )}

      {isShowSubjectsInterest && (
        <SubjectInterestDbContent
          keyProps={key}
          selectedInterestSubjects={selectedSubjectsInterest}
          onAdd={(data) => {
            handleAddSubjectInterest(data);
          }}
        />
      )}
    </>
  );
};

export default IndividualActionPlan8th9th;
