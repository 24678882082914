import React, {Suspense} from 'react';
import {BrowserRouter as Router, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {Roles} from "../../core/enums/roles";
import SignIn from "../../features/auth/components/signIn";
import CreateNewPassword from "../../features/auth/components/createNewPassword";
import Dashboard from "../../features/dashboard/components/dashboard";
import ProtectedRoute from "../protectedRoute/protectedRoute";
import Layout from "../../shared/components/layout/layout";
import Staffs from "../../features/users/staffs/components/staffs";
import CreatePlan from "../../features/plans/components/createPlan";
import OtpVerification from "../../features/auth/components/otpVerification";
import Plans from "../../features/plans/components/plans";
import NotFound from "../../core/components/notFound";
import Entities from "../../features/entity/entity/components/entities";
import Customers from "../../features/users/customers/components/customers";
import RoleProtectedRoute from "../protectedRoute/RoleProtectedRoute";
import StaffCreate from "../../features/users/staffs/components/staffCreate";
import PlanDetails from "../../features/plans/components/planDetails";
import StaffDetails from "../../features/users/staffs/components/staffDetails";
import CreateEntity from "../../features/entity/entity/components/createEntity";
import EntityDetails from "../../features/entity/entity/components/entityDetails";
import AddEntityPlan from "../../features/entity/entity-plan/components/addEntityPlan";
import EntityPlans from "../../features/entity/entity-plan/components/entityPlans";
import EntityPlanDetails from "../../features/entity/entity-plan/components/entityPlanDetails";
import UpdateEntityPlanStageDetails from "../../features/entity/entity-plan/components/updateEntityPlanStageDetails";
import EntityPlanCustomers from "../../features/entity/entity-plan/components/entityPlanCustomers";
import CustomerDetails from "../../features/users/customers/components/customerDetails";
import CustomerPlans from "../../features/users/customers/components/customerPlans";
import Queries from "../../features/query/components/queries";
import Webinars from "../../features/webinar/components/webinars";
import QueryDetails from "../../features/query/components/queryDetails";
import CreateWebinar from "../../features/webinar/components/createWebinar";
import WebinarsDetails from "../../features/webinar/components/webinarsDetails";
import StaffIndividualSessions from "../../features/staff-individual-sessions/components/staffIndividualSessions";
import CreateSession from "../../features/staff-individual-sessions/components/createSession";
import Vlogs from "../../features/vlog/components/vlogs";
import Blogs from "../../features/blog/components/blogs";
import CreateVlog from "../../features/vlog/components/createVlog";
import VlogDetails from "../../features/vlog/components/vlogDetails";
import CreateBlog from "../../features/blog/components/createBlog";
import BlogDetails from "../../features/blog/components/blogDetails";
import CareerLibraries from "../../features/career-library/components/careerLibraries";
import CareerLibraryDetails from "../../features/career-library/components/careerLibraryDetails";
import CounsellorIndividualSessions
  from "../../features/counsellor-individual-sessions/components/counsellorIndividualSessions";
import CounsellorIndividualSessionsDetails
  from "../../features/counsellor-individual-sessions/components/CounsellorIndividualSessionsDetails";
import ScrollToTop from "../scroll-to-top/scrollToTop";
import StaffGroupSessions from "../../features/staff-group-sessions/components/staffGroupSessions";
import StaffGroupSessionDetails from "../../features/staff-group-sessions/components/staffGroupSessionDetails";
import StaffCreateGroupSession from "../../features/staff-group-sessions/components/staffCreateGroupSession";
import CreateCareerLibrary from "../../features/career-library/components/createCareerLibrary";
import CounsellorGroupSessions from "../../features/counsellor-group-sessions/components/counsellorGroupSessions";
import CounsellorCreateGroupActionPlan
  from "../../features/counsellor-group-sessions/components/counsellorCreateGroupActionPlan";
import CounsellorGroupSessionDetails
  from "../../features/counsellor-group-sessions/components/counsellorGroupSessionDetails";
import StaffAssignApproverRequests from "../../features/staff-assign-approvers/components/staffAssignApproverRequests";
import IndividualReviewers from "../../features/staff-assign-approvers/components/individualReviewers";
import GroupReviewers from "../../features/staff-assign-approvers/components/groupReviewers";
import CounsellorApprovalRequests
  from "../../features/counsellor-approval-requests/components/counsellorApprovalRequests";
import CounsellorGroupApprovalRequestDetails
  from "../../features/counsellor-approval-requests/components/counsellorGroupApprovalRequestDetails";
import CounsellorIndividualApprovalRequestDetails
  from "../../features/counsellor-approval-requests/components/CounsellorIndividualApprovalRequestDetails";
import StaffEntitySessions from "../../features/staff-entity-session/components/staffEntitySessions";
import CreateStaffEntitySession from "../../features/staff-entity-session/components/createStaffEntitySession";
import CounsellorEntitySessions from "../../features/counsellor-entity-session/components/counsellorEntitySessions";
import CounsellorEntitySessionDetails
  from "../../features/counsellor-entity-session/components/counsellorEntitySessionDetails";

export const AppRouter: React.FC = () => {

  return (
    <Router>
      <ScrollToTop/>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace/>}/>
          <Route path="/signin" element={<SignIn/>}/>
          <Route path="/email-otp" element={<OtpVerification/>}/>
          <Route path="/create-new-password" element={<CreateNewPassword/>}/>

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Layout>
                  <Dashboard/>
                </Layout>
              </ProtectedRoute>
            }/>

          <Route
            path="/staffs"
            element={
              <ProtectedRoute>
                <Layout>
                  <Outlet/>
                </Layout>
              </ProtectedRoute>
            }>
            <Route index element={<Staffs/>}/>
            <Route path='view-staff/:id' element={<StaffDetails/>}/>
            <Route path="create-staff" element={<StaffCreate/>}/>
            <Route path="update-staff/:id" element={<StaffCreate/>}/>
          </Route>

          <Route
            path="/customers"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Customers/>}/>
            <Route path='view-customer/:id' element={<CustomerDetails/>}/>
            <Route path=':id/plans' element={<CustomerPlans/>}/>
          </Route>

          <Route
            path="/plans"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.SUPER_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Plans/>}/>
            <Route path='view-plan/:id' element={<PlanDetails/>}/>
            <Route path="create-plan" element={<CreatePlan/>}/>
          </Route>

          <Route
            path="/entities"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Entities/>}/>
            <Route path="create-entity" element={<CreateEntity/>}/>
            <Route path="update-entity/:entityId" element={<CreateEntity/>}/>
            <Route path='view-entity/:entityId' element={<EntityDetails/>}/>
            <Route path=':entityId/entity-plans' element={<EntityPlans/>}/>
            <Route path=':entityId/add-entity-plan' element={<AddEntityPlan/>}/>
            <Route path=':entityId/entity-plans/entity-plan-details/:entityPlanId' element={<EntityPlanDetails/>}/>
            <Route path=':entityId/entity-plans/entity-plan-details/:entityPlanId/update-entity-plan-stage'
                   element={<UpdateEntityPlanStageDetails/>}/>
            <Route path=':entityId/entity-plans/:entityPlanId/entity-plan-customers' element={<EntityPlanCustomers/>}/>
          </Route>

          <Route
            path="/staff-entity-sessions"
            element={
            <ProtectedRoute>
              <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                <Layout>
                  <Outlet/>
                </Layout>
              </RoleProtectedRoute>
            </ProtectedRoute>
            }>
            <Route index element={<StaffEntitySessions />}/>
            <Route path='create-staff-entity-session/:id' element={<CreateStaffEntitySession/>}/>
          </Route>

          <Route
            path="/counsellor-entity-sessions"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.IN_HOUSE_COUNSELLOR, Roles.HOD_COUNSELLOR]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<CounsellorEntitySessions />}/>
            <Route path='counsellor-entity-session-details/:id' element={<CounsellorEntitySessionDetails/>}/>
          </Route>

          <Route
            path="/staff-individual-sessions"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<StaffIndividualSessions/>}/>
            <Route path="create-session/:id" element={<CreateSession/>}/>
          </Route>

          <Route
            path="/counsellor-individual-sessions"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.IN_HOUSE_COUNSELLOR, Roles.HOD_COUNSELLOR]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<CounsellorIndividualSessions/>}/>
            <Route path='counsellor-individual-session-details/:id' element={<CounsellorIndividualSessionsDetails/>}/>
          </Route>

          <Route
            path="/staff-group-sessions"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<StaffGroupSessions/>}/>
            <Route path="group-session-details/:id" element={<StaffGroupSessionDetails/>}/>
            <Route path="staff-create-group-session/:id" element={<StaffCreateGroupSession/>}/>
          </Route>

          <Route
            path="/counsellor-group-sessions"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.IN_HOUSE_COUNSELLOR, Roles.HOD_COUNSELLOR]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<CounsellorGroupSessions/>}/>
            <Route path="counsellor-group-session-details/:id" element={<CounsellorGroupSessionDetails/>}/>
            <Route path="counsellor-create-group-action-plan/:id" element={<CounsellorCreateGroupActionPlan/>}/>
          </Route>

          <Route
            path="/staff-assign-approvers"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<StaffAssignApproverRequests/>}/>
            <Route path="individual-reviewers/:id" element={<IndividualReviewers/>}/>
            <Route path="group-reviewers/:id" element={<GroupReviewers/>}/>
          </Route>

          <Route
            path={`/counsellor-approval-requests`}
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.IN_HOUSE_COUNSELLOR, Roles.HOD_COUNSELLOR]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<CounsellorApprovalRequests/>}/>
            <Route path="individual-approval-request-details/:id" element={<CounsellorIndividualApprovalRequestDetails/>}/>
            <Route path="group-approval-request-details/:id" element={<CounsellorGroupApprovalRequestDetails/>}/>
          </Route>

          <Route
            path="/webinars"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Webinars/>}/>
            <Route path='view-webinar/:id' element={<WebinarsDetails/>}/>
            <Route path='create-webinar' element={<CreateWebinar/>}/>
            <Route path='update-webinar/:id' element={<CreateWebinar/>}/>
          </Route>

          <Route
            path="/queries"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Queries/>}/>
            <Route path='view-query/:id' element={<QueryDetails/>}/>
          </Route>

          <Route
            path="/vlogs"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Vlogs/>}/>
            <Route path='view-vlog/:id' element={<VlogDetails/>}/>
            <Route path='create-vlog' element={<CreateVlog/>}/>
            <Route path='update-vlog/:id' element={<CreateVlog/>}/>
          </Route>

          <Route
            path="/blogs"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<Blogs/>}/>
            <Route path='view-blog/:id' element={<BlogDetails/>}/>
            <Route path='create-blog' element={<CreateBlog/>}/>
            <Route path='update-blog/:id' element={<CreateBlog/>}/>
          </Route>

          <Route
            path="/career-libraries"
            element={
              <ProtectedRoute>
                <RoleProtectedRoute allowedRoles={[Roles.STAFF_ADMIN]}>
                  <Layout>
                    <Outlet/>
                  </Layout>
                </RoleProtectedRoute>
              </ProtectedRoute>
            }>
            <Route index element={<CareerLibraries/>}/>
            <Route path='view-career-library/:id' element={<CareerLibraryDetails/>}/>
            <Route path='create-career-library' element={<CreateCareerLibrary/>}/>
          </Route>

          {/* NotFound Route */}
          <Route path="/not-found" element={<NotFound/>}/>
          <Route path="*" element={<NotFound/>}/>

        </Routes>
      </Suspense>
    </Router>
  );
};
