import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {createVlogApi, deleteVlogApi, fetchVlogDetailsApi, fetchVlogsApi, updateVlogApi} from "./vlogsApi";
import {VlogInterface} from "../../../core/interface/vlog/VlogInterface";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetVlogsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<VlogInterface[]>>({
    queryKey: ['vlogs', {page, pageSize}],
    queryFn: () => fetchVlogsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchVlogDetailsApi = (vlogId: any) => {
  return useQuery<DetailsCustomDataResponse<VlogInterface>>({
    queryKey: ['vlog', {vlogId}],
    queryFn: () => fetchVlogDetailsApi(vlogId),
    enabled: !!vlogId
  });
}

export const useCreateVlogApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => createVlogApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['vlogs']});
      const message = 'Vlog created successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['vlogs']});
    }
  })
}

export const useUpdateVlogApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({vlogId, data}: { vlogId: string; data: any }) => updateVlogApi(vlogId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['vlogs']});
      const message = 'Vlog updated successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['vlogs']});
    }
  });
};

export const useDeleteVlogApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vlogId: string) => deleteVlogApi(vlogId),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ["vlogs"]});
      const message = "Vlog deleted successfully!";
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || "Something went wrong.";
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ["vlogs"]});
    },
  });
};
