import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {
  assignReviewerGroupApi,
  assignReviewerIndividualApi,
  fetchGroupAssignApproverRequestsApi,
  fetchGroupReviewerApi,
  fetchIndividualAssignApproverRequestsApi,
  fetchIndividualReviewersApi
} from "./assignApproversApi";
import {
  IndividualAssignApproverRequestInterface
} from "../../../core/interface/staff-assign-approver/individual-assign-approver/IndividualAssignApproverRequestInterface";
import {
  GroupAssignApproverRequestInterface
} from "../../../core/interface/staff-assign-approver/group-assign-approver/GroupAssignApproverRequestInterface";
import {
  IndividualReviewerInterface
} from "../../../core/interface/staff-assign-approver/individual-assign-approver/IndividualReviewerInterface";
import {
  GroupReviewerInterface
} from "../../../core/interface/staff-assign-approver/group-assign-approver/GroupReviewerInterface";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetIndividualAssignApproverRequestsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<IndividualAssignApproverRequestInterface[]>>({
    queryKey: ['individualAssignApprovers', {page, pageSize}],
    queryFn: () => fetchIndividualAssignApproverRequestsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useGetIndividualReviewersApi = (stageId: any, page: number, pageSize: number) => {
  return useQuery<CustomResponse<IndividualReviewerInterface[]>>({
    queryKey: ['individualReviewers', {stageId, page, pageSize}],
    queryFn: () => fetchIndividualReviewersApi(stageId, page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useAssignReviewerIndividualApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({stageId, data}: { stageId: any, data: any }) => assignReviewerIndividualApi(stageId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['individualAssignApprovers']});
      const message = 'Assign reviewer successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['individualAssignApprovers']});
    }
  });
}

export const useGetGroupAssignApproverRequestsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<GroupAssignApproverRequestInterface[]>>({
    queryKey: ['groupAssignApprovers', {page, pageSize}],
    queryFn: () => fetchGroupAssignApproverRequestsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useGetGroupReviewersApi = (groupId: any, page: number, pageSize: number) => {
  return useQuery<CustomResponse<GroupReviewerInterface[]>>({
    queryKey: ['groupReviewers', {groupId, page, pageSize}],
    queryFn: () => fetchGroupReviewerApi(groupId, page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useAssignReviewerGroupApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({groupId, data}: { groupId: any, data: any }) => assignReviewerGroupApi(groupId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['groupAssignApprovers']});
      const message = 'Assign reviewer successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['groupAssignApprovers']});
    }
  });
}
