import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {CareerLibraryInterface} from "../../../core/interface/career-library/CareerLibraryInterface";
import {deleteCareerLibraryIdIdApi, fetchCareerLibrariesApi, fetchCareerLibraryDetailsApi} from "./careerLibraryApi";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetCareerLibrariesApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<CareerLibraryInterface[]>>({
    queryKey: ['careerLibraries', {page, pageSize}],
    queryFn: () => fetchCareerLibrariesApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchCareerLibraryDetailsApi = (careerLibraryId: any) => {
  return useQuery<DetailsCustomDataResponse<CareerLibraryInterface>>({
    queryKey: ['careerLibrary', {careerLibraryId}],
    queryFn: () => fetchCareerLibraryDetailsApi(careerLibraryId),
    enabled: !!careerLibraryId
  });
}

export const useDeleteCareerLibraryApi = () => {
  const { showToastMessage } = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (careerLibraryId: string) => deleteCareerLibraryIdIdApi(careerLibraryId),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["careerLibraries"] });
      const message = "Career library deleted successfully!";
      showToastMessage({ type: ToastType.Success, message });
    },
    onError: (error: any) => {
      const message = error.detail || "Something went wrong.";
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
    },
  });
};
