import React, {useEffect, useMemo, useState} from "react";
import {AddCustomerProps, AddAndUpdateCustomerRequest} from "../../../../../core/interface/customer/AddCustomerProps";
import * as yup from "yup";
import {Button, Modal} from "flowbite-react";
import FormInput from "../../../../../shared/components/form-wrapper/FormInput";
import FormSelect from "../../../../../shared/components/form-wrapper/FormSelect";
import CustomLoaderButton from "../../../../../shared/components/custom-buttons/customLoaderButton";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Grades} from "../../../../../core/enums/grades";
import {useAddCustomerApi, useUpdateCustomerApi} from "../../services/useCustomerApi";

const addCustomerValidationSchema = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  enrollment_number: yup.string().required("Enrollment number is required"),
  grade: yup.string().required("Grade is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  phone_number: yup.string().required("Phone number is required"),
});

const AddCustomerDialog: React.FC<AddCustomerProps> = (props: AddCustomerProps) => {
  
  const GradeOptions = useMemo(() => Object.values(Grades), []);
  
  const [loading, setLoading] = useState(false);
  
  const {
    register: registerAddCustomer,
    setValue: setAddCustomer,
    handleSubmit: handleSubmitAddCustomer,
    formState: {errors: EntityAdminErrors},
    reset
  } = useForm({
    resolver: yupResolver(addCustomerValidationSchema),
  });
  const {mutate: addCustomer} = useAddCustomerApi();
  const {mutate: updateCustomer} = useUpdateCustomerApi();
  
  useEffect(() => {
    if (props.entityPlanCustomer) {
      Object.entries(props.entityPlanCustomer).forEach(([key, value]) => {
        setAddCustomer(key as any, value);
      });
      const formattedPhoneNumber = props.entityPlanCustomer.phone_number?.replace(/^\+91/, '') || '';
      setAddCustomer("phone_number", formattedPhoneNumber);
    }
  }, [props.entityPlanCustomer, setAddCustomer]);
  
  const onSubmitAddCustomerForm = async (data: any) => {
    setLoading(true);
    const addAndUpdateCustomerRequest: AddAndUpdateCustomerRequest = {
      customerId: props.entityPlanCustomer?.id,
      entityId: props.entityId,
      entityPlanId: props.entityPlanId,
      customerData: data
    };
    
    const mutationFn = props.entityPlanCustomer?.id ? updateCustomer : addCustomer;
    
    mutationFn(addAndUpdateCustomerRequest, {
      onSuccess: () => {
        reset();
        props.onClose?.();
      },
      onSettled: () => setLoading(false),
    });
  };
  
  const handleCancel = () => {
    reset();
    props.onClose?.();
  };
  
  return (
    <Modal dismissible={true} show={props.isOpen} onClose={handleCancel} size="lg">
      <div className="flex justify-center pt-4">
        <span className="text-lg font-semibold">
          {props.entityPlanCustomer?.id ? 'Update Customer' : 'Add Customer'}
        </span>
      </div>
      
      <Modal.Body>
        <form onSubmit={handleSubmitAddCustomer(onSubmitAddCustomerForm)}>
          <div className='flex flex-col space-y-2 mb-6'>
            <FormInput
              className="w-full"
              label="First name"
              important={true}
              register={registerAddCustomer("first_name")}
              error={EntityAdminErrors.first_name?.message}
              placeholder="Enter first name"
            />
            <FormInput
              label="Last name"
              important={true}
              register={registerAddCustomer("last_name")}
              error={EntityAdminErrors.last_name?.message}
              placeholder="Enter last name"
            />
            <FormInput
              label="Enrollment number"
              type='number'
              disabled={loading || !!props.entityPlanCustomer?.enrollment_number}
              important={true}
              register={registerAddCustomer("enrollment_number")}
              error={EntityAdminErrors.enrollment_number?.message}
              placeholder="Ente enrollment number"
            />
            <FormSelect
              className='w-full'
              label="Grade"
              important={true}
              options={GradeOptions.map((option) => ({value: option, label: option}))}
              register={registerAddCustomer("grade")}
              disabled={loading || !!props.entityPlanCustomer?.grade}
              error={EntityAdminErrors.grade?.message}
            />
            <FormInput
              label="Email"
              important={true}
              register={registerAddCustomer("email")}
              error={EntityAdminErrors.email?.message}
              placeholder="Enter email address"
            />
            <FormInput
              label="Phone number"
              important={true}
              register={registerAddCustomer("phone_number")}
              error={EntityAdminErrors.phone_number?.message}
              placeholder="Enter phone number"
            />
          </div>
          <div className="flex justify-center space-x-2">
            <Button size='lg' color="gray" onClick={handleCancel} type="button">
              Cancel
            </Button>
            <CustomLoaderButton
              size="lg"
              color="blue"
              type="submit"
              loading={loading}>
              {props.entityPlanCustomer?.id ? 'Update Customer' : 'Add Customer'}
            </CustomLoaderButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCustomerDialog;
