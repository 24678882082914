import React, {useState} from "react";
import {
  useGetGroupAssignApproverRequestsApi,
  useGetIndividualAssignApproverRequestsApi
} from "../services/useAssignApproversApi";
import {Button, Pagination, Spinner, Table} from "flowbite-react";
import {Link} from "react-router-dom";
import {
  IndividualAssignApproverRequestInterface
} from "../../../core/interface/staff-assign-approver/individual-assign-approver/IndividualAssignApproverRequestInterface";
import {
  GroupAssignApproverRequestInterface
} from "../../../core/interface/staff-assign-approver/group-assign-approver/GroupAssignApproverRequestInterface";
import { FaUserCircle } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";

const StaffAssignApproverRequests: React.FC = () => {
  const pageSize = 10;
  const [activeTab, setActiveTab] = useState(0);
  const [individualCurrentPage, setIndividualCurrentPage] = useState<number>(1);
  const [groupCurrentPage, setGroupCurrentPage] = useState<number>(1);

  const { data: individualAssignApproverRequestsList, isLoading: isLoadingIndividual } = useGetIndividualAssignApproverRequestsApi(individualCurrentPage, pageSize);
  const individualAssignApproverRequests: IndividualAssignApproverRequestInterface[] = individualAssignApproverRequestsList?.data.items || [];
  const individualTotalCount = individualAssignApproverRequestsList?.data.total || 0;
  const individualTotalPages = Math.ceil(individualTotalCount / pageSize);

  const { data: groupAssignApproverRequestsList, isLoading: isLoadingGroup } = useGetGroupAssignApproverRequestsApi(groupCurrentPage, pageSize);
  const groupAssignApproverRequests: GroupAssignApproverRequestInterface[] = groupAssignApproverRequestsList?.data.items || [];
  const groupTotalCount = groupAssignApproverRequestsList?.data.total || 0;
  const groupTotalPages = Math.ceil(groupTotalCount / pageSize);

  const handleIndividualPageChange = (page: number) => {
    if (page > 0 && page <= individualTotalPages) {
      setIndividualCurrentPage(page);
    }
  };

  const handleGroupPageChange = (page: number) => {
    if (page > 0 && page <= groupTotalPages) {
      setGroupCurrentPage(page);
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-5">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">Assign Approvers</h1>
        <p className="text-sm text-gray-500">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin accumsan sed neque non consequat.
          Curabitur consectetur turpis tortor, in tincidunt mauris iaculis et. Suspendisse condimentum et nunc sed
          dapibus.
        </p>
      </div>

      <div className="flex shadow-md rounded-lg overflow-hidden mb-4">
        <div
          onClick={() => setActiveTab(0)}
          className={`flex-1 py-3 flex justify-center items-center cursor-pointer border-r ${
            activeTab === 0
              ? "bg-white text-gray-800 font-semibold"
              : "bg-gray-50 text-gray-500 hover:text-gray-800"
          }`}
        >
          <FaUserCircle size={20} className="mr-2" />Individual Reports/Action Plan
        </div>
        <div
          onClick={() => setActiveTab(1)}
          className={`flex-1 py-3 flex justify-center items-center cursor-pointer ${
            activeTab === 1
              ? "bg-white text-gray-800 font-semibold"
              : "bg-gray-50 text-gray-500 hover:text-gray-800"
          }`}
        >
          <HiUserGroup size={20} className="mr-2" /> Group Action Plan
        </div>
      </div>

      {activeTab === 0 ? (
        <div className="relative shadow-md sm:rounded-lg">
          <Table striped>
            <Table.Head className="border-b border-gray-200">
              <Table.HeadCell>Plan Name</Table.HeadCell>
              <Table.HeadCell>Stage Name</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>

            {isLoadingIndividual ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={3} className="py-5 text-center">
                    <Spinner size="lg"/>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : individualTotalCount === 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={3} className="py-5 text-center text-gray-500">
                    No individual assign approvers records found.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body className="divide-y">
                {individualAssignApproverRequests.map((individualApproverRequest) => (
                  <Table.Row
                    key={individualApproverRequest.stage_id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {individualApproverRequest.plan_name}
                    </Table.Cell>
                    <Table.Cell>{individualApproverRequest.stage_name}</Table.Cell>
                    <Table.Cell className="flex items-center space-x-2">
                      <Link to={`individual-reviewers/${individualApproverRequest?.stage_id}`}>
                        <Button color="blue" size="xs">
                          Assign Approver
                        </Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>

          {individualTotalCount > 0 && (
            <div className="flex justify-between items-center p-6 border-t">
              <p className="text-gray-500">
                Showing{" "}
                <span className="font-semibold text-black">
                    {pageSize * (individualCurrentPage - 1) + 1}-
                  {Math.min(pageSize * individualCurrentPage, individualTotalCount)}
                  </span>{" "}
                of <span className="font-semibold text-black">{individualTotalCount}</span>
              </p>
              <Pagination
                currentPage={individualCurrentPage}
                totalPages={individualTotalPages}
                onPageChange={handleIndividualPageChange}
                showIcons={true}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="relative shadow-md sm:rounded-lg">
          <Table striped>
            <Table.Head className="border-b border-gray-200">
              <Table.HeadCell>Entity Plan Name</Table.HeadCell>
              <Table.HeadCell>Group Name</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>

            {isLoadingGroup ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={3} className="py-5 text-center">
                    <Spinner size="lg"/>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : groupTotalCount === 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={3} className="py-5 text-center text-gray-500">
                    No group assign approvers records found.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body className="divide-y">
                {groupAssignApproverRequests.map((groupApproverRequest) => (
                  <Table.Row
                    key={groupApproverRequest.customer_group_session_id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {groupApproverRequest.entity_plan_name}
                    </Table.Cell>
                    <Table.Cell>{groupApproverRequest.group_name}</Table.Cell>
                    <Table.Cell className="flex items-center space-x-2">
                      <Link to={`group-reviewers/${groupApproverRequest?.customer_group_session_id}`}>
                        <Button color="blue" size="xs">
                          Assign Approver
                        </Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>

          {groupTotalCount > 0 && (
            <div className="flex justify-between items-center p-6 border-t">
              <p className="text-gray-500">
                Showing{" "}
                <span className="font-semibold text-black">
                    {pageSize * (groupCurrentPage - 1) + 1}-
                  {Math.min(pageSize * groupCurrentPage, groupTotalCount)}
                  </span>{" "}
                of <span className="font-semibold text-black">{groupTotalCount}</span>
              </p>
              <Pagination
                currentPage={groupCurrentPage}
                totalPages={groupTotalPages}
                onPageChange={handleGroupPageChange}
                showIcons={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StaffAssignApproverRequests;
