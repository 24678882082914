import { Grades } from "../../core/enums/grades";

export const renderBreadcrumbLabel = (customerGrades: string[]): string => {
  if (!Array.isArray(customerGrades) || customerGrades.length === 0) {
    return "Action Plan (Other)";
  }

  if (customerGrades.includes(Grades.EIGHT) || customerGrades.includes(Grades.NINE)) {
    return "Action Plan (8th, 9th)";
  }

  if (customerGrades.includes(Grades.TENTH)) {
    return "Action Plan (10th)";
  }

  return "Action Plan (Other)";
};
