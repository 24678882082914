import React from "react";
import {HiCheck} from "react-icons/hi";

export interface StrategyListProps {
  strategies: Strategy[];
  selectedStrategy: string | null;
  onSelect: (index: string, strategy: string, title: string) => void;
}

export interface Strategy {
  title: string;
  strategies: string[];
}

const StrategyList: React.FC<StrategyListProps> = ({strategies, selectedStrategy, onSelect}) => {
  return (
    <>
      {strategies.map((learningStyleStrategies, index) => (
        <div key={index}>
          <h3 className="bg-blue-50 text-xl font-bold p-4 rounded-md mb-4">{learningStyleStrategies.title}</h3>
          <div className="flex flex-col space-y-4">
            {learningStyleStrategies.strategies.map((strategy, strategyIndex) => (
              <div
                key={strategyIndex}
                className={`rounded-md p-4 cursor-pointer ${
                  selectedStrategy === strategy ? "border-2 border-gray-700" : "border border-gray-300"
                }`}
                onClick={() => onSelect(`Strategy-${strategyIndex + 1}`, strategy, learningStyleStrategies.title)}
              >
                <div className="flex items-center justify-between mb-3">
                  <span className="border border-green-600 p-2 rounded-full text-base font-semibold text-green-600">
                    Strategy-{strategyIndex + 1}
                  </span>
                  {selectedStrategy === strategy && (
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id={strategy}
                        name="strategy"
                        checked={selectedStrategy === strategy}
                        onChange={() => onSelect(`Strategy-${strategyIndex}`, strategy, learningStyleStrategies.title)}
                        className="hidden peer"
                      />
                      <label
                        htmlFor={strategy}
                        className="flex items-center justify-center w-6 h-6 border border-gray-300 rounded-full peer-checked:border-blue-600 peer-checked:bg-blue-600 peer-checked:text-white"
                      >
                        <HiCheck color="white" />
                      </label>
                    </div>
                  )}
                </div>
                <div className="border border-gray-300 rounded-md p-4 text-sm font-medium text-gray-500">
                  {strategy}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default StrategyList;
