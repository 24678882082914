import React from "react";
import {useLocation, useParams} from "react-router-dom";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Spinner} from "flowbite-react";
import {useFetchEntitySessionDetailsApi} from "../../staff-entity-session/services/useStaffEntitySessionApi";
import {StaffEntitySessionInterface} from "../../../core/interface/staff-entity-session/StaffEntitySessionInterface";
import SessionStageDetails from "../../stage/components/stageSessionDetails/sessionStageDetails";

const CounsellorEntitySessionDetails: React.FC = () => {

  const {id} = useParams();
  const location = useLocation();
  const {data: entitySessionDetails, isLoading: isLoadingStageDetails} = useFetchEntitySessionDetailsApi(location.state?.planId, id);
  const entitySession: StaffEntitySessionInterface | undefined = entitySessionDetails?.data;

  return (
    <div>
      <div className="mb-4">
        <BackButton to={`/counsellor-entity-sessions`} label="Back"/>
        <h1 className="text-xl font-bold mb-4">{entitySession?.stage_name}</h1>
        <CustomBreadcrumb
          items={[
            {label: "Entity Sessions", to: "/counsellor-entity-sessions"},
            {label: "Entity Sessions Details"},
          ]}
        />
      </div>
      <div className="border border-gray-300 rounded-md shadow-md p-6 pb-20 bg-white">
        {isLoadingStageDetails ? (
          <div className="flex justify-center items-center py-5">
            <Spinner size="lg"/>
          </div>
        ) : (
          <>
            <SessionStageDetails
              planId={entitySession?.entity_plan_id}
              stageId={id}
              stageStatus={entitySession?.stage_state}
              stageType={entitySession?.stage_type}
              sessionName={entitySession?.dynamic_data?.SESSION_DATA?.name}
              datetime={entitySession?.dynamic_data?.SESSION_DATA?.datetime}
              durationInMins={entitySession?.dynamic_data?.SESSION_DATA?.duration_in_mins}
              mode={entitySession?.dynamic_data?.SESSION_DATA?.mode}
              hostName={entitySession?.dynamic_data?.SESSION_DATA?.host_name}
              meetingLinkHost={entitySession?.dynamic_data?.SESSION_DATA?.meeting_link_host}
              sessionType='EntitySession'
              navigateUrl='/counsellor-entity-sessions'
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CounsellorEntitySessionDetails;
