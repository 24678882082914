import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {
  CounsellorGroupSessionInterface
} from "../../../core/interface/counsellor-group-sessions/CounsellorGroupSessionInterface";
import {
  CounsellorGroupSessionDetailsInterface
} from "../../../core/interface/counsellor-group-sessions/CounsellorGroupSessionDetailsInterface";

// Fetch counsellor group sessions
export const fetchCounsellorGroupSessionsApi = async (page: number, pageSize: number): Promise<CustomResponse<CounsellorGroupSessionInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<CounsellorGroupSessionInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/counsellor/group-sessions`,
    {params: queryParams}
  );
  return response.data;
};

// Counsellor group session details
export const fetchGroupSessionDetailsApi = async (groupId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<CounsellorGroupSessionDetailsInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/group/${groupId}`);
  return res.data;
}

// Create group action plan
export const createGroupActionPlanApi = async (groupId: any, actionPlanData: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/group/${groupId}/group-action-plan`, actionPlanData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Complete group session
export const completeGroupSessionApi = async (groupId: any)=> {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/group/${groupId}`, '');
    return response.data;
  }  catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}
