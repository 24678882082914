import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {createGroupSessionApi, fetchCustomersGroupApi, fetchGroupSessionsApi} from "./staffGroupSessionsApi";
import {StaffGroupSessionInterface} from "../../../core/interface/staff-group-session/StaffGroupSessionInterface";
import {GroupCustomerInterface} from "../../../core/interface/staff-group-session/GroupCustomerInterface";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetGroupSessionsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<StaffGroupSessionInterface[]>>({
    queryKey: ['staffGroupSessions', {page, pageSize}],
    queryFn: () => fetchGroupSessionsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useGetCustomersGroupApi = (planId: any, page: number, pageSize: number) => {
  return useQuery<CustomResponse<GroupCustomerInterface[]>>({
    queryKey: ['customersGroup', {planId, page, pageSize}],
    queryFn: () => fetchCustomersGroupApi(planId, page, pageSize),
    retry: false,
    staleTime: 300000,
  })
}

export const useCreateGroupSessionApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({stageId, data}: {
      stageId: any,
      data: any
    }) => createGroupSessionApi(stageId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['staffGroupSessions']});
      const message = 'Group session created successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['staffGroupSessions']});
    }
  });
}
