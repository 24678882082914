import axios from "axios";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {
  IndividualAssignApproverRequestInterface
} from "../../../core/interface/staff-assign-approver/individual-assign-approver/IndividualAssignApproverRequestInterface";
import {
  GroupAssignApproverRequestInterface
} from "../../../core/interface/staff-assign-approver/group-assign-approver/GroupAssignApproverRequestInterface";
import {
  IndividualReviewerInterface
} from "../../../core/interface/staff-assign-approver/individual-assign-approver/IndividualReviewerInterface";
import {
  GroupReviewerInterface
} from "../../../core/interface/staff-assign-approver/group-assign-approver/GroupReviewerInterface";

// Fetch individual assign approver requests
export const fetchIndividualAssignApproverRequestsApi = async (page: number, pageSize: number): Promise<CustomResponse<IndividualAssignApproverRequestInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<IndividualAssignApproverRequestInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/individual/approval-requests`,
    {params: queryParams}
  );
  return response.data;
};

// Fetch individual reviewer list
export const fetchIndividualReviewersApi = async (stageId: any, page: number, pageSize: number): Promise<CustomResponse<IndividualReviewerInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<IndividualReviewerInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/${stageId}/reviewers`,
    {params: queryParams}
  );
  return response.data;
};

// Assign reviewer for individual
export const assignReviewerIndividualApi = async (stageId: any, data: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/stage/${stageId}/assign-approver`, data);
    return response.data;
  }  catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Fetch group assign approver requests
export const fetchGroupAssignApproverRequestsApi = async (page: number, pageSize: number): Promise<CustomResponse<GroupAssignApproverRequestInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<GroupAssignApproverRequestInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/group-action-plan/approval-requests`,
    {params: queryParams}
  );
  return response.data;
};

// Fetch group reviewer list
export const fetchGroupReviewerApi = async (groupId: any, page: number, pageSize: number): Promise<CustomResponse<GroupReviewerInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<GroupReviewerInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/group/${groupId}/reviewers`,
    {params: queryParams}
  );
  return response.data;
};

// Assign reviewer for group
export const assignReviewerGroupApi = async (groupId: any, data: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/group/${groupId}/assign-approver`, data);
    return response.data;
  }  catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}
