import axios from "axios";
import {CustomersCsvUploadRequestProps} from "../../../../core/interface/customer/UploadCustomerStepProps";
import {AddAndUpdateCustomerRequest} from "../../../../core/interface/customer/AddCustomerProps";
import {CustomerInterface, CustomerRequest} from "../../../../core/interface/customer/CustomerInterface";
import {CustomResponse, DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import {DisableRequest} from "../../../../core/interface/DisableRequest";
import {CustomerPlanInterface, CustomerPlanRequest} from "../../../../core/interface/customer/CustomerPlanInterface";

// Upload Customer by csv
export const uploadCustomersApi = async (customersUploadData: CustomersCsvUploadRequestProps) => {
  const formData = new FormData();
  formData.append('file', customersUploadData.customersCsvFile);
  
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${customersUploadData.entityId}/plan/${customersUploadData.entityPlanId}/customers/upload?format=csv`,
      formData
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Create customer
export const addCustomerApi = async (customerData: AddAndUpdateCustomerRequest) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${customerData.entityId}/plan/${customerData.entityPlanId}/customer`, customerData.customerData
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Update customer
export const updateCustomerApi = async (customerData: AddAndUpdateCustomerRequest) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${customerData.entityId}/plan/${customerData.entityPlanId}/customer/${customerData.customerId}`, customerData.customerData
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Fetch customers (B2B & B2C)
export const fetchCustomersApi = async (customerRequest: CustomerRequest): Promise<CustomResponse<CustomerInterface[]>> => {
  const {
    currentPage,
    pageSize,
    filteredSearchQuery,
    selectedBusinessVerticalOptions,
    selectedEntityTypeOptions
  } = customerRequest;
  
  const params = new URLSearchParams({
    ...(currentPage && {page_num: currentPage.toString()}),
    ...(pageSize && {size: pageSize.toString()}),
    ...(filteredSearchQuery && {search: filteredSearchQuery})
  });
  
  selectedBusinessVerticalOptions?.forEach(businessVertical => {
    params.append("business_vertical", businessVertical);
  });
  
  selectedEntityTypeOptions?.forEach(entityType => {
    params.append("entity_type", entityType);
  });
  
  const response = await axios.get<CustomResponse<CustomerInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/customers`,
    {params}
  );
  
  return response.data;
};

// Customer details
export const fetchCustomerDetailsApi = async (customerId: string | undefined) => {
  const res = await axios.get<DetailsCustomDataResponse<CustomerInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/customer/${customerId}`);
  return res.data;
}

// Customer disable
export const disableCustomerApi = async (disableData: DisableRequest) => {
  const data = {disable_reason: disableData.reason};
  const params = {status: disableData.status};
  
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/customer/${disableData.id}`, data, {params}
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
};

// Fetch customer plans
export const fetchCustomerPlansApi = async (customerPlanRequest: CustomerPlanRequest): Promise<CustomResponse<CustomerPlanInterface[]>> => {
  const {
    currentPage,
    pageSize,
    selectedPlanStatus,
  } = customerPlanRequest;
  
  const params = new URLSearchParams({
    ...(currentPage && {page_num: currentPage.toString()}),
    ...(pageSize && {size: pageSize.toString()}),
  });
  
  selectedPlanStatus?.forEach(status => {
    params.append("status", status);
  });
  
  const response = await axios.get<CustomResponse<CustomerPlanInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/customer/${customerPlanRequest.customerId}/plans`,
    {params}
  );
  
  return response.data;
};

// Export customer
export const exportCustomersApi = async (entityId: any, entityPlanId: any) => {
  const response = await axios.get<any>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/entity/${entityId}/plan/${entityPlanId}/customers/export`,
    {
      responseType: "blob", // Fetches binary data
    }
  );
  return { data: response.data, headers: response.headers };
};

//
