import {useQuery} from "@tanstack/react-query";
import {DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import {DbContentInterface} from "../../../../core/interface/db-content/DbContentInterface";
import {fetchDbContentApi} from "./dbContentApi";

export const useFetchDbContentApi = (key: any) => {
  return useQuery<DetailsCustomDataResponse<DbContentInterface>>({
    queryKey: ['dbContent', {key}],
    queryFn: () => fetchDbContentApi(key),
    enabled: !!key
  });
}
