import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {
  CounsellorGroupSessionInterface
} from "../../../core/interface/counsellor-group-sessions/CounsellorGroupSessionInterface";
import {
  completeGroupSessionApi,
  createGroupActionPlanApi,
  fetchCounsellorGroupSessionsApi,
  fetchGroupSessionDetailsApi
} from "./counsellorGroupSessionApi";
import {
  CounsellorGroupSessionDetailsInterface
} from "../../../core/interface/counsellor-group-sessions/CounsellorGroupSessionDetailsInterface";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetCounsellorGroupSessionsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<CounsellorGroupSessionInterface[]>>({
    queryKey: ['counsellorGroupSessions', {page, pageSize}],
    queryFn: () => fetchCounsellorGroupSessionsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchCounsellorGroupDetailsApi = (groupId: any) => {
  return useQuery<DetailsCustomDataResponse<CounsellorGroupSessionDetailsInterface>>({
    queryKey: ['counsellorGroupSession', {groupId}],
    queryFn: () => fetchGroupSessionDetailsApi(groupId),
    enabled: !!groupId
  })
}

export const useCreateGroupActionPlanApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({groupId, data}:{groupId: any, data: any}) => createGroupActionPlanApi(groupId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['counsellorGroupSessions']});
      const message = 'Group action plan created successfully!';
      showToastMessage({ type: ToastType.Success, message });
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['webinars']});
    }
  })
}

export const useCompleteGroupSessionApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({groupId}: { groupId: any }) => completeGroupSessionApi(groupId),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['counsellorGroupSessions']});
      const message = 'Group session completed successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['counsellorGroupSessions']});
    }
  });
}
