import React, {useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FileInput, Label} from "flowbite-react";
import FormInput from "../../../../shared/components/form-wrapper/FormInput";
import FormTextarea from "../../../../shared/components/form-wrapper/FormTextarea";
import CustomLoaderButton from "../../../../shared/components/custom-buttons/customLoaderButton";
import {CreateCareerLibraryTabProps} from "../../../../core/interface/career-library/props/CreateCareerLibraryTabProps";

const validationSchema = yup.object({
  name: yup.string().required("Title is required").trim(),
  desc: yup
    .string()
    .required("Description is required")
    .trim()
    .min(5, "Description must be at least 5 characters"),
  thumbnail_image: yup
    .mixed()
    .required("Image is required")
    .test("fileType", "Only image files are allowed", (value) =>
      value instanceof FileList &&
      value.length > 0 &&
      value[0].type.startsWith("image/")
    ),
  video: yup
    .mixed()
    .required("Video is required")
    .test("fileType", "Only video files are allowed", (value) =>
      value instanceof FileList &&
      value.length > 0 &&
      value[0].type.startsWith("video/")
    ),
});

const About: React.FC<CreateCareerLibraryTabProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = (data: any) => {
    setLoading(true);
    const {thumbnail_image, video, ...json_data} = data;
    const aboutData = {
      thumbnail_image: thumbnail_image?.[0],
      video: video?.[0],
      json_data,
    }
    setTimeout(() => {
      props.onSubmit(aboutData);
      setLoading(false);
    }, 2000);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormInput
          className="mb-4"
          label="Title"
          important
          register={register("name")}
          error={errors.name?.message}
          placeholder="Enter title"
          disabled={loading}
        />

        <FormTextarea
          className="mb-4"
          label="Description"
          important
          register={register("desc")}
          error={errors.desc?.message}
          placeholder="Enter description"
          disabled={loading}
        />

        <div className="flex space-x-6 mb-4">
          <div className="w-full">
            <Label htmlFor="uploadImage" className="mb-2 block">
              Upload Image
            </Label>
            <FileInput
              {...register("thumbnail_image")}
              id="uploadImage"
              accept="image/*"
              disabled={loading}
            />
            {errors.thumbnail_image && (
              <span className="text-red-600">
                      {errors.thumbnail_image.message as string}
                    </span>
            )}
          </div>
        </div>
        <div className="flex space-x-6 mb-4">
          <div className="w-full">
            <Label htmlFor="uploadVideo" className="mb-2 block">
              Upload Video
            </Label>
            <FileInput
              {...register("video")}
              id="uploadVideo"
              accept="video/*"
              disabled={loading}
            />
            {errors.video && (
              <span className="text-red-600">
                      {errors.video.message as string}
                    </span>
            )}
          </div>
        </div>

        <CustomLoaderButton size="lg" color="blue" type="submit" loading={loading}>
          Next Step: Fact
        </CustomLoaderButton>
      </form>
    </div>
  );
};

export default About;
