import React, {useState} from "react";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import VerticalStepper from "../../../shared/components/custom-stepper/verticalStepper";
import About from "./career-library-tab/about";

const careerLibrarySteps = [
  { key: "about", label: "About" },
  { key: "fact", label: "Fact" },
  { key: "careerLife", label: "Career Life" },
  { key: "jobsProspect", label: "Jobs Prospect" },
  { key: "careerJourneyTree", label: "Career Journey Tree" },
  { key: "workLifeBalance", label: "Work Life Balance" },
  { key: "eligibility", label: "Eligibility" },
  { key: "entranceExam", label: "Entrance Exam" },
  { key: "institutions", label: "Institutions" },
  { key: "jobSkills", label: "Job Skills" },
  { key: "boomingCareers", label: "Booming Careers" },
  { key: "topCollegesStateWise", label: "Top Colleges State Wise" },
  { key: "syllabusUG", label: "Syllabus of the Courses (UG)" },
  { key: "syllabusPG", label: "Syllabus of the Courses (PG)" },
  { key: "certificationCourses", label: "Certification Courses" },
  { key: "internshipDetails", label: "Internship Details" },
  { key: "associationBodies", label: "Association Bodies" },
  { key: "licencingBodies", label: "Licencing Bodies" },
];


const CreateCareerLibrary: React.FC = () => {

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<any>({});

  const handleStepSubmit = (data: any) => {
    setFormData(data);
    console.log('FormData', formData);

    if (currentStep < careerLibrarySteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      console.log("Final Data:", formData);
      alert("All steps completed!");
    }
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton to="/career-libraries" label="Back"/>
        <h1 className="text-xl font-bold mb-4">Create Career Library</h1>
        <CustomBreadcrumb
          items={[
            {label: "Career Libraries", to: "/career-libraries"},
            {label: "Create Career Library"}
          ]}
        />
      </div>
      <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
        <div className="grid grid-cols-4 gap-5">
         <div className="col-span-1">
           <VerticalStepper
             steps={careerLibrarySteps.map((step) => step.label)}
             currentStep={currentStep}
           />
         </div>
          <div className="col-span-3">
            {currentStep === 0 && <About onSubmit={handleStepSubmit} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCareerLibrary;
