import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import axios from "axios";
import {StaffEntitySessionInterface} from "../../../core/interface/staff-entity-session/StaffEntitySessionInterface";

// Fetch entity session
export const fetchStaffEntitySessionsApi = async (page: number, pageSize: number): Promise<CustomResponse<StaffEntitySessionInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<StaffEntitySessionInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stages/entity-sessions`,
    {params: queryParams}
  );
  return response.data;
};

// Fetch entity session details
export const fetchStaffEntitySessionDetailsApi = async (planId: any, stageId: any) => {
  const response = await axios.get<DetailsCustomDataResponse<StaffEntitySessionInterface>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}/stage/${stageId}`,
  );
  return response.data;
}

// Create entity session
export const createStaffEntitySessionApi = async (planId: any, stageId: any, entitySessionData: any) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}/stage/${stageId}/add-entity-session`, entitySessionData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}
