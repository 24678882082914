import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextarea from "../../../../shared/components/form-wrapper/FormTextarea";
import ResourceCardList from "./resourceCardList/resourceCardList";
import {useNavigate} from "react-router-dom";
import {ResourceResponseProps} from "../../../../core/interface/counsellor-individual-sessions/ResourceRequestProps";
import {ActionPlanProps} from "../../../../core/interface/counsellor-individual-sessions/ActionPlanProps";
import CustomLoaderButton from "../../../../shared/components/custom-buttons/customLoaderButton";
import {useCreateGroupActionPlanApi} from "../../services/useCounsellorGroupSessionApi";

// Validation schema without required fields
const schema = yup.object().shape({
  feedback: yup.string().min(10, "Feedback must be at least 10 characters").required("Feedback is required"),
  areas_of_improvement: yup.string().required("Areas of improvement is required"),
  customer_suggestion: yup.string().required("Customer suggestion is required"),
  additional_info: yup.string(),
  resource: yup.array().min(1, "At least one resource is required").required("Resources are required") // Set resource to be required
});

const GroupActionPlan8th9th: React.FC<ActionPlanProps> = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {mutate: createActionPlan} = useCreateGroupActionPlanApi();

  const onSubmit = (data: any) => {
    setLoading(true);
    const actionPlanData = {
      plan_applicable_grades: props.planApplicableGrades,
      feedback: data.feedback,
      areas_of_improvement: data.areas_of_improvement,
      suggestions: {
        customer_suggestion: data.customer_suggestion
      },
      resource: data.resource,
    }
    createActionPlan({groupId: props.groupId, data: actionPlanData}, {
      onSuccess: () => navigate("/counsellor-group-sessions"),
      onSettled: () => setLoading(false)
    })
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className={`text-2xl leading-tight font-bold mb-3`}>Action Plan (8th, 9th)</h2>
        <div className={`border-b pb-5 mb-5`}>
          <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>1. Feedback</h3>
          <FormTextarea
            className="w-full mb-4"
            subClassName="h-32"
            register={register("feedback")}
            error={errors.feedback?.message}
            placeholder="Write text here ..."
          />
        </div>

        <div className={`border-b pb-5 mb-5`}>
          <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>4. Areas of improvement</h3>
          <FormTextarea
            className="w-full mb-4"
            subClassName="h-32"
            register={register("areas_of_improvement")}
            error={errors.feedback?.message}
            placeholder="Write text here ..."
          />
        </div>

        <div className={`border-b pb-5 mb-5`}>
          <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>5. Suggestions</h3>
          <FormTextarea
            className="w-full mb-4"
            subClassName="h-32"
            label="Recommendations for client"
            register={register("customer_suggestion")}
            error={errors.customer_suggestion?.message}
            placeholder="Write text here ..."
          />
        </div>

        <div>
          <h3 className={`bg-blue-50 text-xl font-bold p-4 rounded-md mb-4`}>5. Suggestions</h3>
          <ResourceCardList onResourcesChange={(data: ResourceResponseProps[]) => setValue('resource', data)}/>
          {errors.resource && <span className="text-red-600">{errors.resource.message}</span>}
        </div>

        <CustomLoaderButton loading={loading} color='blue' size='lg' type='submit'>
          Submit
        </CustomLoaderButton>
      </form>
    </>
  );
};

export default GroupActionPlan8th9th;
