import React from "react";
import {Button, Card} from "flowbite-react";
import {HiOutlineMinusCircle, HiOutlinePlusSm} from "react-icons/hi";
import {ResourceCardProps} from "../../../../../core/interface/counsellor-individual-sessions/ResourceRequestProps";
import FormInput from "../../../../../shared/components/form-wrapper/FormInput";

const ResourceCard: React.FC<ResourceCardProps> = (
  {
    resource,
    onUpdateTitle,
    onAddUrl,
    onUpdateUrl,
    onRemoveUrl,
  }) => {
  const {title, urls} = resource;

  return (
    <Card className="w-full">
      <FormInput
        label="Title"
        placeholder="Enter Title"
        type="text"
        value={title}
        className="mb-4"
        onChangeInput={(e) => onUpdateTitle(resource.id, e.target.value)}
      />

      {urls.map((url, index) => (
        <div key={index} className="relative flex items-center">
          <FormInput
            addon="https"
            placeholder="Enter URL"
            type="url"
            value={url}
            className="w-full"
            onChangeInput={(e) => onUpdateUrl(resource.id, index, e.target.value)}
          />
          <Button color="none" onClick={() => onRemoveUrl(resource.id, index)}
                  className="absolute right-0 text-gray-500 hover:text-red-500">
            <HiOutlineMinusCircle size={20}/>
          </Button>
        </div>
      ))}

      <Button color="none" onClick={() => onAddUrl(resource.id)} className="flex justify-start">
        <HiOutlinePlusSm size={20} className="text-blue-800 mr-2"/> Add Links
      </Button>
    </Card>
  );
};

export default ResourceCard;
