import React, {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BreadcrumbStepper from "../../../shared/components/custom-stepper/breadcrumbStepper";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import CustomersWithCheckBox from "./customer-list/customersWithCheckBox";
import RadioInput from "../../../shared/components/form-wrapper/radioInput";
import {SessionMode} from "../../../core/enums/stage/sessionMode";
import FormDateTimePicker from "../../../shared/components/form-wrapper/formDateTimePicker";
import AvailableCounsellorHost
  from "../../../shared/components/counsellor-host-list/components/availableCounsellorHost";
import {Button} from "flowbite-react";
import {GroupCustomerInterface} from "../../../core/interface/staff-group-session/GroupCustomerInterface";
import {CounsellorHostInterface} from "../../../core/interface/host/CounsellorHostInterface";
import {useCreateGroupSessionApi} from "../services/useStaffGroupSessionsApi";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";

const breadcrumbSteps = [
  "Select customers",
  "Select date & time",
  "Find available counsellor"
];

const StaffCreateGroupSession: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams<{ id: string }>();
  const [planId] = useState(location.state?.planId || null);
  const [createGroupSessionLoading, setCreateGroupSessionLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>("");
  const [breadcrumbStep, setBreadcrumbStep] = useState(0);
  const [selectedCustomers, setSelectedCustomers] = useState<GroupCustomerInterface[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedMode, setSelectedMode] = useState<string>("");
  const [selectedHost, setSelectedHost] = useState<CounsellorHostInterface | null>(null);

  const {mutate: createGroupSession} = useCreateGroupSessionApi();

  const handleNext = () => {
    if (breadcrumbStep === 1) {
      const validationErrors: any = {};
      if (!selectedMode) {
        validationErrors.mode = "Mode is required";
      }
      if (!selectedDate) {
        validationErrors.datetime = "Date and time is required";
      }
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return;
      }
    }

    setBreadcrumbStep((prevStep) => prevStep + 1);
  };

  const handleCreateGroupSession = () => {
    setCreateGroupSessionLoading(true);
    const createGroupSessionData = {
      entity_plan_id: planId,
      session: {
        name: location.state?.sessionName,
        mode: selectedMode,
        audience: "GROUP",
        duration_in_mins: location.state?.sessionDuration,
        host: selectedHost?.host_id,
        host_name: selectedHost?.name,
        datetime: selectedDate
      },
      customers: selectedCustomers
    }

    createGroupSession({stageId: id, data: createGroupSessionData}, {
      onSuccess: () => navigate('/staff-group-sessions'),
      onSettled: () => setCreateGroupSessionLoading(false),
    });
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton to="/staff-group-sessions" label="Back"/>
        <h1 className="text-2xl font-bold mb-4">Create Group Session</h1>
        <CustomBreadcrumb
          items={[
            {label: "Group Sessions", to: "/staff-group-sessions"},
            {label: "Create Group Session"},
          ]}
        />
      </div>

      <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
        <div className="mb-4">
          <BreadcrumbStepper steps={breadcrumbSteps} currentStep={breadcrumbStep}/>
        </div>
        <div>
          {breadcrumbStep === 0 && (
            <CustomersWithCheckBox
              planId={planId}
              selectedCustomers={selectedCustomers}
              onCustomerSelect={setSelectedCustomers}
            />
          )}
          {breadcrumbStep === 1 && (
            <div className="mb-4">
              <RadioInput
                className="col-span-2 w-full mb-4"
                name="entrance_exam_preparation"
                label="Mode"
                value={selectedMode}
                options={[
                  {label: "Online", value: SessionMode.ONLINE},
                  {label: "Offline", value: SessionMode.OFFLINE},
                ]}
                onChangeInput={(value: any) => setSelectedMode(value)}
                error={errors.mode}
              />
              <FormDateTimePicker
                className="flex flex-col w-1/2"
                name="datetime"
                label="Date and Time"
                register={{
                  value: selectedDate ? new Date(selectedDate) : null,
                  onChange: (value: Date | null) => {
                    const isoDate = value ? value.toISOString() : "";
                    setSelectedDate(isoDate);
                  },
                }}
                minDate={new Date()}
                placeholder="Select date and time"
                error={errors.datetime}
              />
            </div>
          )}
          {breadcrumbStep === 2 && (
            <AvailableCounsellorHost
              stageId={id}
              planId={planId}
              timePeriods={selectedDate}
              sessionDuration={8}
              selectedHost={selectedHost}
              onHostSelect={setSelectedHost}
            />
          )}
        </div>

        <div className="flex items-center space-x-2 mt-4">
          {breadcrumbStep > 0 && (
            <button
              className="bg-gray-200 px-4 py-2 rounded-md"
              onClick={() => setBreadcrumbStep(breadcrumbStep - 1)}>
              Prev
            </button>
          )}
          {breadcrumbStep < 2 && (
            <Button
              color={breadcrumbStep === 0 && selectedCustomers.length === 0 ? "gray" : "blue"}
              onClick={handleNext}
              disabled={breadcrumbStep === 0 && selectedCustomers.length === 0}
            >
              Next
            </Button>
          )}
          {breadcrumbStep === 2 && (
            <CustomLoaderButton color="blue" loading={createGroupSessionLoading}
              onClick={handleCreateGroupSession}>
              Create Group Session
            </CustomLoaderButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffCreateGroupSession;
