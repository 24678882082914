import React, { useEffect, useCallback, useState } from "react";
import { Table, TextInput } from "flowbite-react";

interface FormChartTableProps {
  onChange: (data: { day_of_the_week: string; task: string[] }[]) => void;
  selectedData?: { day_of_the_week: string; task: string[] }[];
}

const FormChartTable: React.FC<FormChartTableProps> = ({ onChange, selectedData }) => {
  let daysOfWeek: any[];
  daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Initialize the chart data
  const initializeChartData = () => {
    const emptyRows = Array.from({ length: 8 }, () => Array(7).fill(""));
    if (selectedData && selectedData.length > 0) {
      selectedData.forEach(({ day_of_the_week, task }) => {
        const colIndex = daysOfWeek.indexOf(day_of_the_week);
        if (colIndex !== -1) {
          task.forEach((value, rowIndex) => {
            emptyRows[rowIndex][colIndex] = value || "";
          });
        }
      });
    }
    return emptyRows;
  };

  const [chartData, setChartData] = useState<string[][]>(initializeChartData);

  useEffect(() => {
    setChartData(initializeChartData());
  }, [selectedData]);

  const handleInputChange = useCallback(
    (rowIndex: number, colIndex: number, value: string) => {
      const updatedData = chartData.map((row, rIndex) =>
        rIndex === rowIndex
          ? row.map((col, cIndex) => (cIndex === colIndex ? value : col))
          : row
      );
      setChartData(updatedData);

      const output = daysOfWeek.map((day, colIndex) => {
        const tasks = updatedData.map((row) => row[colIndex]);
        return { day_of_the_week: day, task: tasks };
      });
      onChange(output);
    },
    [chartData, daysOfWeek, onChange]
  );

  return (
    <div className="mt-6">
      <h3 className="text-lg font-semibold mb-4">Chart Table</h3>
      <div className="overflow-x-auto relative">
        <Table striped className="border border-gray-200">
          <Table.Head className="bg-gray-100">
            {daysOfWeek.map((day, index) => (
              <Table.HeadCell
                key={index}
                className="text-left text-sm font-medium text-gray-600 border border-gray-200"
              >
                {day}
              </Table.HeadCell>
            ))}
          </Table.Head>
          <Table.Body>
            {chartData.map((row, rowIndex) => (
              <Table.Row key={rowIndex} className="bg-white">
                {row.map((col, colIndex) => (
                  <Table.Cell
                    key={colIndex}
                    className="border border-gray-200 p-2"
                  >
                    <TextInput
                      type="text"
                      placeholder="Enter Task"
                      value={col}
                      onChange={(e) =>
                        handleInputChange(rowIndex, colIndex, e.target.value)
                      }
                      sizing="md"
                      className="rounded-md w-full"
                    />
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default FormChartTable;
