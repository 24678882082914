import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {WebinarInterface} from "../../../core/interface/webinar/WebinarInterface";
import {createWebinarApi, fetchWebinarDetailsApi, fetchWebinarsApi, updateWebinarApi} from "./webinarApi";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetWebinarsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<WebinarInterface[]>>({
    queryKey: ['webinars', {page, pageSize}],
    queryFn: () => fetchWebinarsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchWebinarDetailsApi = (webinarId: any) => {
  return useQuery<DetailsCustomDataResponse<WebinarInterface>>({
    queryKey: ['webinar', {webinarId}],
    queryFn: () => fetchWebinarDetailsApi(webinarId),
    enabled: !!webinarId
  })
}

export const useCreateWebinarApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => createWebinarApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['webinars']});
      const message = 'Webinar created successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['webinars']});
    }
  })
}

export const useUpdateWebinarApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({webinarId, data}: { webinarId: string; data: any }) => updateWebinarApi(webinarId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['webinars']});
      const message = 'Webinar updated successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['webinars']});
    }
  });
};
