import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {completeCounsellorEntitySessionApi, fetchCounsellorEntitySessionsApi} from "./counsellorEntitySessionApi";
import {
  CounsellorEntitySessionInterface
} from "../../../core/interface/counsellor-entity-session/CounsellorEntitySessionInterface";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetCounsellorEntitySessionsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<CounsellorEntitySessionInterface[]>>({
    queryKey: ['counsellorEntitySessions', {page, pageSize}],
    queryFn: () => fetchCounsellorEntitySessionsApi(page, pageSize),
    retry: false,
    staleTime: 0,
  });
};

export const useCompleteCounsellorEntitySessionApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({planId, stageId, data}: {planId: any, stageId: any, data: any }) => completeCounsellorEntitySessionApi(planId, stageId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['counsellorEntitySessions']});
      const message = 'Individual session completed successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['counsellorEntitySessions']});
    }
  });
}
