import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../../core/interface/CustomResponse";
import {
  StaffInterface,
  StaffsRequestParams
} from "../../../../core/interface/staff/StaffInterface";
import {createStaffApiApi, disableStaffApi, fetchStaffDetailsApi, fetchStaffsApi, updateStaffApi} from "./staffApi";
import {useToast} from "../../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../../core/enums/toastType";
import {DisableRequest} from "../../../../core/interface/DisableRequest";

export const useGetStaffsApi = (staffRequest: StaffsRequestParams, isEnabled: boolean = true) => {
  return useQuery<CustomResponse<StaffInterface[]>>({
    queryKey: ['staffs', {staffRequest}],
    queryFn: () => fetchStaffsApi(staffRequest),
    enabled: isEnabled,
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchStaffDetailsApi = (staffId: string | undefined) => {
  return useQuery<DetailsCustomDataResponse<StaffInterface>>({
    queryKey: ['plan', {staffId}],
    queryFn: () => fetchStaffDetailsApi(staffId),
    enabled: !!staffId
  });
}

export const useCreateStaffApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => createStaffApiApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['staffs']});
      const message = 'Staff created successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['staffs']});
    }
  })
}

export const useUpdateStaffApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({staffId, data}: { staffId: string; data: any }) => updateStaffApi(staffId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['staffs']});
      const message = 'Staff updated successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['staffs']});
    }
  });
};

export const useDisableStaffApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({data}: { data: DisableRequest }) => disableStaffApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['staffs']});
      const message = 'Staff deleted successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    }
  })
}
