import axios from "axios";
import {DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {Stage} from "../../../core/interface/stage/Stage";

// Fetch stage details
export const fetchStageDetailsApi = async (stageId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<Stage>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/stage/${stageId}`
  );
  return res.data;
}
