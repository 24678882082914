import React from "react";


interface PdfViewerProps {
  pdfUrl?: any;
}

const PdfViewer: React.FC<PdfViewerProps> = ({pdfUrl}) => {
  return (
    <div>
      <iframe
        title="Report pdf"
        src={pdfUrl}
        width="800px"
        height="800px"
        style={{border: "none"}}
      />
    </div>
  );
};

export default PdfViewer;
