import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {createSessionApi, fetchStaffIndividualSessionsApi} from "./staffIndividualSessionsApi";
import {
  StaffIndividualSessionsInterface
} from "../../../core/interface/staff-individual-session/StaffIndividualSessionsInterface";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetStaffIndividualSessionsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<StaffIndividualSessionsInterface[]>>({
    queryKey: ['staffIndividualSessions', {page, pageSize}],
    queryFn: () => fetchStaffIndividualSessionsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useCreateSessionApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({planId, stageId, data}: {
      planId: any,
      stageId: any,
      data: any
    }) => createSessionApi(planId, stageId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['staffIndividualSessions']});
      const message = 'Session created successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['staffIndividualSessions']});
    }
  });
}
