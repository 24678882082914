import React, {useState} from "react";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Spinner} from "flowbite-react";
import {useNavigate, useParams} from "react-router-dom";
import {HiCheck} from "react-icons/hi";
import FormDateTimePicker from "../../../shared/components/form-wrapper/formDateTimePicker";
import {useCreateSessionApi} from "../services/useStaffIndividualSessionsApi";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";
import RadioInput from "../../../shared/components/form-wrapper/radioInput";
import {SessionMode} from "../../../core/enums/stage/sessionMode";
import {useFetchStageDetailsApi} from "../../stage/services/useStageApi";
import {Stage} from "../../../core/interface/stage/Stage";
import AvailableCounsellorHost
  from "../../../shared/components/counsellor-host-list/components/availableCounsellorHost";
import {CounsellorHostInterface} from "../../../core/interface/host/CounsellorHostInterface";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";

const CreateSession: React.FC = () => {
  const navigate = useNavigate();
  const {id} = useParams<{ id: string }>();
  const [createSessionLoading, setCreateSessionLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [requestSelectedDate, setRequestSelectedDate] = useState<string>("");
  const [selectedMode, setSelectedMode] = useState<string>("");
  const [selectedHost, setSelectedHost] = useState<CounsellorHostInterface | null>(null);
  const [hostCount, setHostCount] = useState<number>(0);
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>("");

  const {mutate: createSession} = useCreateSessionApi();
  const {data: fetchStageDetails, isLoading: isLoadingStageDetails} = useFetchStageDetailsApi(id);
  const stageDetails: Stage | undefined = fetchStageDetails?.data;

  const handleDateChange = () => {
    if (selectedDate) {
      setRequestSelectedDate(selectedDate);
      setIsShowButton(false);
    }
  };

  const handleCreateSession = () => {
    if (!selectedMode && !stageDetails?.dynamic_data?.PREFERRED_TIME_SLOTS) {
      return setErrors("Please select mode");
    }
    setCreateSessionLoading(true);
    const createSessionData = {
      name: stageDetails?.dynamic_data?.SESSION_DATA?.name,
      audience: stageDetails?.dynamic_data?.SESSION_DATA?.audience,
      duration_in_mins: stageDetails?.dynamic_data?.SESSION_DATA?.duration_in_mins,
      mode: stageDetails?.dynamic_data?.PREFERRED_TIME_SLOTS ? stageDetails.dynamic_data.PREFERRED_TIME_SLOTS.mode : selectedMode,
      host: selectedHost?.host_id,
      host_name: selectedHost?.name,
      datetime: selectedDate
    }
    createSession({planId: stageDetails?.customer_plan_id, stageId: id, data: createSessionData}, {
      onSuccess: () => navigate('/staff-individual-sessions'),
      onSettled: () => setCreateSessionLoading(false),
    });
  }

  return (
    <div>
      <div className="mb-4">
        <BackButton to="/staff-individual-sessions" label="Back"/>
        <h1 className="text-xl font-bold mb-4">Create Session</h1>
        <CustomBreadcrumb
          items={[
            {label: "Individual Sessions", to: "/individual-sessions"},
            {label: "Create Session"},
          ]}
        />
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        {isLoadingStageDetails ? (
          <div className="flex justify-center items-center py-5">
            <Spinner size="lg"/>
          </div>
        ) : (
          <>
            <div className="mb-5">
              <p className="mb-4 font-medium">Preferred Date & Time</p>
              {stageDetails?.dynamic_data?.PREFERRED_TIME_SLOTS ? (
                <div className="flex flex-wrap gap-4 mb-4">
                  {stageDetails.dynamic_data.PREFERRED_TIME_SLOTS.time_slots.map((value) => (
                    <label key={value} className="flex items-center cursor-pointer space-x-2">
                      <input
                        type="radio"
                        name="preferredDate"
                        value={value}
                        checked={selectedDate === value}
                        onChange={() => {
                          setSelectedDate(value);
                          setIsShowButton(true);
                          setSelectedHost(null);
                        }}
                        className="hidden peer"
                      />
                      <span
                        className="w-5 h-5 border-2 border-gray-300 rounded-sm flex items-center justify-center peer-checked:bg-blue-500 peer-checked:border-blue-500"
                      >
                        <HiCheck color="white"/>
                      </span>
                      <span>{dateAndTimeFormatter(value, 'MMMM DD YYYY, h:mm:A')}</span>
                    </label>
                  ))}
                </div>
              ) : (
                <div className="mb-4">
                  <RadioInput
                    className="col-span-2 w-full mb-4"
                    name="entrance_exam_preparation"
                    label="Mode"
                    value={selectedMode}
                    options={[
                      { label: "Online", value: SessionMode.ONLINE },
                      { label: "Offline", value: SessionMode.OFFLINE },
                    ]}
                    onChangeInput={(value: any) => setSelectedMode(value)}
                    error={errors}
                  />
                  <FormDateTimePicker
                    className="flex flex-col w-1/2"
                    name="datetime"
                    label="Date and Time"
                    register={{
                      value: selectedDate ? new Date(selectedDate) : null,
                      onChange: (value: Date | null) => {
                        const isoDate = value ? value.toISOString() : "";
                        setSelectedDate(isoDate);
                        setIsShowButton(true);
                      },
                    }}
                    minDate={new Date()}
                    placeholder="Select date and time"
                  />
                </div>
              )}

              <CustomLoaderButton color="blue" onClick={handleDateChange} disabled={!isShowButton}>
                Fetch Available Hosts
              </CustomLoaderButton>
            </div>
            <div>
              {requestSelectedDate && !isShowButton && (
                <AvailableCounsellorHost
                  stageId={id}
                  planId={stageDetails?.customer_plan_id}
                  timePeriods={requestSelectedDate}
                  sessionDuration={stageDetails?.dynamic_data?.SESSION_DATA?.duration_in_mins}
                  selectedHost={selectedHost}
                  onHostSelect={setSelectedHost}
                  onHostListCount={setHostCount}
                />
              )}
              {hostCount > 0 && !isShowButton && (
                <CustomLoaderButton loading={createSessionLoading} onClick={handleCreateSession} color="blue"
                                    disabled={!selectedHost}>
                  Create Session
                </CustomLoaderButton>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateSession;
