import React from "react";
import {StepperProps} from "../../../core/interface/StepperProps";

const BreadcrumbStepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
  return (
    <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm sm:space-x-4 sm:p-4 mb-4">
      {steps.map((step, index) => (
        <li key={index} className={`flex items-center ${index <= currentStep ? "text-blue-600" : "text-gray-500"}`}>
          <div className={`flex flex-wrap justify-center`}>
            <span className={`flex items-center justify-center w-5 h-5 mr-2 text-xs border rounded-full ${
              index <= currentStep
                ? "border-blue-600 text-blue-600"
                : "border-gray-500"
            }`}
            >
            {index + 1}
          </span>
            {step}
          </div>
          {index < steps.length - 1 && (
            <svg
              className="w-3 h-3 ml-2 sm:ml-4 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 12 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m7 9 4-4-4-4M1 9l4-4-4-4"
              />
            </svg>
          )}
        </li>
      ))}
    </ol>
  );
};

export default BreadcrumbStepper;
