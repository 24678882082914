import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {CareerLibraryInterface} from "../../../core/interface/career-library/CareerLibraryInterface";

// Fetch career library
export const fetchCareerLibrariesApi = async (page: number, pageSize: number,): Promise<CustomResponse<CareerLibraryInterface[]>> => {
  const params = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });

  const response = await axios.get<CustomResponse<CareerLibraryInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/career-libraries`, {params: params},
  );
  return response.data;
};

// Career library details
export const fetchCareerLibraryDetailsApi = async (careerLibraryId: any) => {
  const res = await axios.get<DetailsCustomDataResponse<CareerLibraryInterface>>(`${process.env.REACT_APP_BASE_URL}/v1/staffs/career-library/${careerLibraryId}`);
  return res.data;
}

// Delete career library by ID
export const deleteCareerLibraryIdIdApi = async (careerLibraryId: string) => {
  const params = new URLSearchParams({
    userpool_type: "staffs",
  });

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/career-library/${careerLibraryId}`, {params: params},
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error("An unexpected error occurred.");
  }
};
