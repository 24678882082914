import React, {useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {Grades} from "../../../core/enums/grades";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import GroupActionPlan8th9th from "./group-action-plan/groupActionPlan8th9th";
import {renderBreadcrumbLabel} from "../../../shared/utils/renderBreadcrumbLabel";

const CounsellorCreateGroupActionPlan: React.FC = () => {

  const {id} = useParams();
  const location = useLocation();
  const [customerGrades] = useState<string[]>(location.state?.customerGrade || [])

  const renderActionPlanStageComponent = () => {
    // Define a mapping of grade groups to components
    const gradeComponentMap: Record<string, React.ReactNode> = {
      [`${Grades.EIGHT},${Grades.NINE}`]: (
        <GroupActionPlan8th9th
          groupId={id}
          planApplicableGrades={customerGrades}
        />
      ),
    };

    for (const gradeKey of Object.keys(gradeComponentMap)) {
      const gradeArray = gradeKey.split(",") as Grades[];
      if (customerGrades?.some((grade) => gradeArray.includes(grade as Grades))) {
        return gradeComponentMap[gradeKey];
      }
    }
    return <div>Unknown stage group</div>;
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton to={`/counsellor-group-sessions`} label="Back"/>
        <h1 className="text-xl font-bold mb-4">Action Plan</h1>
        <CustomBreadcrumb
          items={[
            {label: "Counsellor Group Sessions", to: "/counsellor-group-sessions"},
            {label: renderBreadcrumbLabel(customerGrades)},
          ]}
        />
      </div>

      <div className="border border-gray-300 rounded-md shadow-md p-6 pb-20 bg-white">
        {renderActionPlanStageComponent()}
      </div>
    </div>
  );
};

export default CounsellorCreateGroupActionPlan;
