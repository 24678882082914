import React, {useState} from "react";
import {Table, Spinner, Modal, Button} from "flowbite-react";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import ProfileField from "../../../shared/components/profile-field/profileField";
import {useParams} from "react-router-dom";
import {useFetchStageDetailsApi} from "../../stage/services/useStageApi";
import {Stage} from "../../../core/interface/stage/Stage";

const StaffGroupSessionDetails: React.FC = () => {
  const {id} = useParams<{ id: string }>();
  const {data: fetchStageDetails, isLoading: isLoadingStageDetails} = useFetchStageDetailsApi(id);
  const stageDetails: Stage | undefined = fetchStageDetails?.data;

  console.log('StageDetails', stageDetails);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
  
  const groupSessions = [
    {
      groupName: "Group 1",
      sessionDetails: "25-11-2024, 11:00am",
      counsellorName: "Cristofer Schleifer",
      detailedCustomers: [
        {name: "Abram Botosh", category: "Science"},
        {name: "Kadin Franci", category: "Arts"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Charlie Schleifer", category: "Science"},
        {name: "Jaydon George", category: "Arts"},
        {name: "Zain George", category: "Commerce"},
        {name: "Ahmad Vetrows", category: "Commerce"},
      ],
    },
    {
      groupName: "Group 2",
      sessionDetails: "25-11-2024, 11:00am",
      counsellorName: "Dulce Baptista",
      detailedCustomers: [
        {name: "Abram Botosh", category: "Science"},
        {name: "Kadin Franci", category: "Arts"},
        {name: "Jakob Westervelt", category: "Commerce"},
      ],
    },
    {
      groupName: "Group 3",
      sessionDetails: "25-11-2024, 11:00am",
      counsellorName: "Alfonso Ekstrom Bothman",
      detailedCustomers: [
        {name: "Abram Botosh", category: "Science"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
        {name: "Jakob Westervelt", category: "Commerce"},
      ],
    },
  ];
  
  const handleOpenModal = (group: any) => {
    setSelectedGroup(group);
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setSelectedGroup(null);
    setIsModalOpen(false);
  };
  
  return (
    <div>
      <div className="mb-4">
        <BackButton to="/staff-group-sessions" label="Back"/>
        <h1 className="text-2xl font-bold mb-4">Group Session Details</h1>
        <CustomBreadcrumb
          items={[
            {label: "Group Sessions", to: "/staff-group-sessions"},
            {label: "Group Session Details"},
          ]}
        />
      </div>
      
      {isLoadingStageDetails ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
            <ProfileField label="Plan Name" value="Plan 1"/>
            <ProfileField label="Session Name" value="Pathfinder Sessions"/>
            <ProfileField label="Session Mode" value="Online"/>
            <ProfileField label="Customers Count" value="5"/>
          </div>
          
          <h2 className="text-2xl font-bold mb-5">
            Group Session List
          </h2>
          <div className={`overflow-x-auto relative shadow-md sm:rounded-lg`}>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head className={`text-gray-500`}>
                  <Table.HeadCell>Group Name</Table.HeadCell>
                  <Table.HeadCell>Session Details</Table.HeadCell>
                  <Table.HeadCell>Counsellor Name</Table.HeadCell>
                  <Table.HeadCell>Customers List</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {groupSessions.map((group, index) => (
                    <Table.Row key={index} className="hover:bg-gray-50">
                      <Table.Cell>{group.groupName}</Table.Cell>
                      <Table.Cell>{group.sessionDetails}</Table.Cell>
                      <Table.Cell>{group.counsellorName}</Table.Cell>
                      <Table.Cell>
                        <div className={`flex items-center`}>
                          {group.detailedCustomers
                            .slice(0, 3)
                            .map((customer) => customer.name)
                            .join(", ")}
                          {group.detailedCustomers.length > 3 && (
                            <>
                              ,
                              <Button
                                className={`ml-2 text-blue-700`}
                                onClick={() => handleOpenModal(group)} color='none' size='none'>
                                More +{group.detailedCustomers.length - 3}
                              </Button>
                            </>
                          )}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      )}
      
      {selectedGroup && (
        <Modal show={isModalOpen} size="md">
          <Modal.Body>
            <div className="flex justify-center items-center flex-col mb-4">
              <h2 className="text-base font-bold text-gray-900">{selectedGroup.groupName}</h2>
              <span className="text-lg font-bold text-blue-700">Customers List</span>
            </div>
            <div className="overflow-y-auto max-h-64 px-4 space-y-2 mb-5"
                 style={{ scrollbarWidth: "thin", scrollbarColor: "#1A56DB #EDF2F7" }}>
              {selectedGroup.detailedCustomers.map((customer: any, idx: number) => (
                <span key={idx} className="block">
                  {idx + 1}. {customer.name} ({customer.category})
                </span>
              ))}
            </div>
            <div className="flex justify-center items-center">
              <Button color="blue" onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default StaffGroupSessionDetails;
