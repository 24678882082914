import React from "react";
import {Badge} from "flowbite-react";
import {IoIosLink} from "react-icons/io";
import {ProfileFieldProps} from "../../../core/interface/ProfileFieldProps";

const DetailField: React.FC<ProfileFieldProps> = (props) => {

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const maxLength = 100;

  return (
    <div className={`mb-4 ${props.className}`}>
      <h3 className="text-xs font-medium text-blue-700 mb-2">{props.label}</h3>
      {Array.isArray(props.value) ? (
        <div className="flex space-x-2 border-b-2 border-blue-500 pb-1">
          {props.value.map((val, index) => (
            <Badge
              key={index}
              color="gray"
              className="py-1 text-sm text-gray-900 font-medium"
            >
              {val}
            </Badge>
          ))}
        </div>
      ) : (props.isLink ? (
          <a
            href={props.value as string}
            target="_blank"
            rel="noopener noreferrer"
            className="border-none pb-1 flex items-center text-blue-600 hover:underline"
          >
            <IoIosLink className="mr-2"/>
            {truncateText(props.value as string, maxLength)}
          </a>
        ) : (
          <p className="border-b-2 border-blue-500 pb-1 flex items-center">
            {truncateText(props.value as string, maxLength)}
          </p>
        )
      )}
    </div>
  );
};

export default DetailField;
