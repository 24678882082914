import React, { useEffect, useRef } from "react";
import {StepperProps} from "../../../core/interface/StepperProps";

const VerticalStepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
  // Ref array to track each step
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
  
  useEffect(() => {
    // Scroll the current step into view
    if (stepRefs.current[currentStep]) {
      stepRefs.current[currentStep]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [currentStep]);
  
  return (
    <div className="w-full overflow-x-auto">
      <ol className="flex flex-row md:flex-col space-x-4 md:space-x-0 space-y-0 md:space-y-4 w-max md:w-full">
        {steps.map((step, index) => (
          <li key={index} className="flex-shrink-0">
            <div
              ref={(el) => (stepRefs.current[index] = el)} // Attach ref to each step
              className={`w-full p-4 border rounded-lg text-center min-w-[150px] ${
                index < currentStep
                  ? "bg-green-50 border-green-300 text-green-700"
                  : index === currentStep
                    ? "bg-blue-100 border-blue-300 text-blue-700"
                    : "bg-gray-100 border-gray-300 text-gray-900"
              }`}
            >
              <div className="flex flex-row justify-between items-center">
                <h3 className="font-medium text-sm md:text-base mr-2 md:mr-0">
                  {index + 1}. {step}
                </h3>
                {index < currentStep && (
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                )}
                {index === currentStep && (
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                )}
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default VerticalStepper;
