import React from "react";
import {useParams} from "react-router-dom";
import {useFetchCareerLibraryDetailsApi} from "../services/useCareerLibraryApi";
import {CareerLibraryInterface} from "../../../core/interface/career-library/CareerLibraryInterface";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Spinner} from "flowbite-react";

const CareerLibraryDetails: React.FC = () => {

  const {id} = useParams<{ id: string }>();
  const {data, isLoading} = useFetchCareerLibraryDetailsApi(id);
  const careerLibraryDetails: CareerLibraryInterface | undefined = data?.data;

  return (
    <div>
      <div className="mb-4">
        <BackButton to="/career-libraries" label="Back"/>
        <h1 className="text-xl font-bold mb-4">View Career Library</h1>
        <CustomBreadcrumb
          items={[
            {label: "Career Libraries", to: "/career-libraries"},
            {label: "Career Library Details"}
          ]}
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center py-5">
          <Spinner size="lg"/>
        </div>
      ) : (
        <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
            <div className={`col-span-2 w-full flex flex-col justify-start items-start`}>
              <h3 className="text-xs font-medium text-blue-700 mb-2">Video Thumbnail</h3>
              <img src={careerLibraryDetails?.about.thumbnail_image_url} alt={careerLibraryDetails?.name}
                   className="w-40 h-40"/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerLibraryDetails;
