import axios from "axios";
import {CustomResponse} from "../../../../core/interface/CustomResponse";
import {AvailableCounsellorHostRequest} from "../../../../core/interface/host/AvailableCounsellorHostRequest";
import {CounsellorHostInterface} from "../../../../core/interface/host/CounsellorHostInterface";

// Fetch available counsellor host
export const fetchAvailableCounsellorHostsApi = async (data: AvailableCounsellorHostRequest): Promise<CustomResponse<CounsellorHostInterface[]>> => {
  const params = new URLSearchParams();
  if (data.currentPage) {
    params.set("page_num", data.currentPage.toString());
  }

  if (data.pageSize) {
    params.set("size", data.pageSize.toString());
  }

  if (data.sessionDuration) {
    params.set("session_duration", data.sessionDuration.toString());
  }

  if (data.timePeriods) {
    params.append("time_periods", data.timePeriods.toString());
  }
  const response = await axios.get<CustomResponse<CounsellorHostInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${data.planId}/stage/${data.stageId}/hosts/COUNSELLOR`,
    {params: params,}
  );
  return response.data;
}
