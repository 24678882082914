import {CustomResponse} from "../../../core/interface/CustomResponse";
import axios from "axios";
import {
  CounsellorIndividualSessionInterface
} from "../../../core/interface/counsellor-individual-sessions/CounsellorIndividualSessionInterface";

// Fetch counsellor individual sessions
export const fetchCounsellorIndividualSessionsApi = async (page: number, pageSize: number): Promise<CustomResponse<CounsellorIndividualSessionInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<CounsellorIndividualSessionInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/counsellor/customer-plans`,
    {params: queryParams}
  );
  return response.data;
};

// Create individual action plan
export const createIndividualActionPlanApi = async (planId: any, stageId: any, actionPlanData: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}/stage/${stageId}/customer-action-plan`, actionPlanData);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Complete individual session
export const completeIndividualSessionApi = async (data: any)=> {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/complete-session`, data);
    return response.data;
  }  catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}
