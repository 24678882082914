import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {createPlanApi, disablePlanApi, fetchPlanDetailsApi, fetchPlansApi} from "./planApi";
import {PlanInterface} from "../../../core/interface/plan/PlanInterface";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";

export const useGetPlansApi = (page?: number, pageSize?: number, selectedGrade?: any) => {
  return useQuery<CustomResponse<PlanInterface[]>>({
    queryKey: ['plans', {page, pageSize, selectedGrade}],
    queryFn: () => fetchPlansApi(page, pageSize, selectedGrade),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchPlanDetailsApi = (planId: string | undefined) => {
  return useQuery<DetailsCustomDataResponse<PlanInterface>>({
    queryKey: ['plan', {planId}],
    queryFn: () => fetchPlanDetailsApi(planId),
    enabled: !!planId
  })
}

export const useCreatePlanApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (data: any) => createPlanApi(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['plans']});
      const message = 'Staff updated successfully!';
      showToastMessage({ type: ToastType.Success, message });
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['plans']});
    }
  })
};

export const useDisablePlanApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: disablePlanApi,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['plans']});
      const message = 'Entity created successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({ type: ToastType.Error, message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['plans']});
    }
  });
};
