import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse} from "../../../core/interface/CustomResponse";
import {
  approveGroupApprovalRequestApi,
  approveIndividualApprovalRequestApi, fetchCounsellorIndividualReport,
  fetchGroupApprovalRequestsApi, fetchCounsellorGroupReport,
  fetchIndividualApprovalRequestsApi,
  rejectGroupApprovalRequestApi,
  rejectIndividualApprovalRequestApi
} from "./counsellorApprovalRequestApi";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";
import {
  CounsellorIndividualApprovalRequestInterface
} from "../../../core/interface/counsellor-approval/CounsellorIndividualApprovalRequestInterface";
import {
  CounsellorGroupApprovalRequestInterface
} from "../../../core/interface/counsellor-approval/CounsellorGroupApprovalRequestInterface";

export const useGetIndividualApprovalRequestsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<CounsellorIndividualApprovalRequestInterface[]>>({
    queryKey: ['individualApprovalRequests', {page, pageSize}],
    queryFn: () => fetchIndividualApprovalRequestsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchCounsellorIndividualReport = (planId: any, stageId: any) => {
  return useQuery<CustomResponse<CounsellorIndividualApprovalRequestInterface[]>>({
    queryKey: ['counsellorIndividualReport', {planId, stageId}],
    queryFn: () => fetchCounsellorIndividualReport(planId, stageId),
    retry: false,
    staleTime: 300000,
  });
};

export const useApproveIndividualApprovalRequestApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({planId, stageId}: {
      planId: any,
      stageId: any
    }) => approveIndividualApprovalRequestApi(planId, stageId),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['individualApprovalRequests']});
      const message = 'Approval request approved successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['individualApprovalRequests']});
    }
  });
};

export const useRejectIndividualApprovalRequestApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({planId, stageId, data}: {
      planId: any,
      stageId: any,
      data: any
    }) => rejectIndividualApprovalRequestApi(planId, stageId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['individualApprovalRequests']});
      const message = 'Approval request rejected successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['individualApprovalRequests']});
    }
  });
};


export const useGetGroupApprovalRequestsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<CounsellorGroupApprovalRequestInterface[]>>({
    queryKey: ['groupApprovalRequests', {page, pageSize}],
    queryFn: () => fetchGroupApprovalRequestsApi(page, pageSize),
    retry: false,
    staleTime: 300000,
  });
};

export const useFetchCounsellorGroupReport = (groupId: any) => {
  return useQuery<CustomResponse<CounsellorIndividualApprovalRequestInterface[]>>({
    queryKey: ['counsellorGroupReport', {groupId}],
    queryFn: () => fetchCounsellorGroupReport(groupId),
    retry: false,
    staleTime: 300000,
  });
};


export const useApproveGroupApprovalRequestApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({groupId}: { groupId: any }) => approveGroupApprovalRequestApi(groupId),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['groupApprovalRequests']});
      const message = 'Approval request approved successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['groupApprovalRequests']});
    }
  });
}

export const useRejectGroupApprovalRequestApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({groupId, data}: { groupId: any, data: any }) => rejectGroupApprovalRequestApi(groupId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['groupApprovalRequests']});
      const message = 'Approval request rejected successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['groupApprovalRequests']});
    }
  });
}
