import React, {useState} from "react";
import {useGetCounsellorEntitySessionsApi} from "../services/useCounsellorEntitySessionApi";
import {
  CounsellorEntitySessionInterface
} from "../../../core/interface/counsellor-entity-session/CounsellorEntitySessionInterface";
import {Badge, Button, Pagination, Spinner, Table, Tooltip} from "flowbite-react";
import {getStageShortName} from "../../../shared/utils/getStageShortName";
import {Link} from "react-router-dom";
import {SessionMode} from "../../../core/enums/stage/sessionMode";

const CounsellorEntitySessions: React.FC = () => {

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, isLoading} = useGetCounsellorEntitySessionsApi(currentPage, pageSize);
  const counsellorIndividualSession: CounsellorEntitySessionInterface[] = data?.data.items || [];
  const totalCount = data?.data.total || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-5">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">Entity Session</h1>
      </div>

      <div className={`overflow-x-auto relative shadow-md sm:rounded-lg`}>
        <div className="overflow-x-auto">
          <Table striped>
            <Table.Head className='border-b border-gray-200'>
              <Table.HeadCell className={`text-gray-500`}>Entity Name</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Plan Name</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Session Name</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Session Mode</Table.HeadCell>
              <Table.HeadCell className={`text-gray-500`}>Stages</Table.HeadCell>
            </Table.Head>

            <Table.Body className="divide-y">
              {isLoading ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <div className="flex justify-center items-center py-5">
                      <Spinner size="lg"/>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : totalCount === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <div className="flex justify-center items-center py-5">
                      <div className="tet-center text-gray-500">No counsellor individual session records found.</div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : (
                <>
                  {counsellorIndividualSession.map((session, index) => (
                    <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>
                        {session.entity_name}
                      </Table.Cell>
                      <Table.Cell>
                        {session.entity_plan_name}
                      </Table.Cell>
                      <Table.Cell>
                        {session.session_name}
                      </Table.Cell>
                      <Table.Cell>
                        <div className='flex'>
                          <Badge color={session.session_mode === SessionMode.ONLINE ? 'blue' : 'gray'}>
                            {session.session_mode}
                          </Badge>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`counsellor-entity-session-details/${session?.stage_id}`}
                        state={{planId:session?.entity_plan_id}}>
                          <Tooltip content={session.stage_name} placement="top">
                            <Button
                              className={`rounded-full h-8 w-8 flex items-center justify-center p-5 bg-blue-600 text-white`}
                              color="blue"
                              size="xs"
                            >
                              {getStageShortName(session.stage_type)}
                            </Button>
                          </Tooltip>
                        </Link>
                      </Table.Cell>

                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
        </div>
        {totalCount > 0 && (
          <div className="flex justify-between items-center p-6 border-t">
            <p className="text-gray-500">
              Showing <span
              className="font-semibold text-black">{pageSize * (currentPage - 1) + 1}-{Math.min(pageSize * currentPage, totalCount)}</span> of <span
              className="font-semibold text-black">{totalCount}</span>
            </p>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              showIcons={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CounsellorEntitySessions;
