const stageShortNames: Record<string, string> = {
  "ORIENTATION": "OCS",
  "B2B-1ON1-COUNSELLING-SESSION": "ICS",
  "B2C-COUNSELLING-SESSION": "ICS",
  "PARENT-SESSION": "PCS",
  "COUNSELLING-IDENTIFY-SKILL-APTITUDE-SESSION": "SAS",
  "COUNSELLING-CAREER-EXPLORATION-SESSION": "CES",
  "COUNSELLING-CAREER-PATH-IDENTIFIER-SESSION": "PIS",
  "COUNSELLING-SOFT-SKILL-SESSION": "SSS",
  "COUNSELLING-HIGHER-EDUCATION-SESSION": "HES",
  "COUNSELLING-GENERAL-HIGHER-EDUCATION-SESSION": "GHS",
  "COUNSELLING-CAREER-JOURNEY-SESSION": "CJS",
  "COUNSELLING-WORK-LIFE-BALANCE-SESSION": "WLS",
  "PSYCHOMETRIC-ASSESSMENT-REPORT": "PAR",
  "COUNSELLOR-CREATE-ACTION-PLAN": "CAP",
  "PARENT-SESSION-REPORT": "PSR",
  "10-12-ACCELERATE-FINAL-REPORT": "AFR",
  "B2B-GROUP-COUNSELLING-SESSION": "GCS",
  "COUNSELLOR-CREATE-GENERALISED-ACTION-PLAN": "GAP",
  "EXPERT-SESSION-REPORT": "ESR",
  "B2B-1ON1-ENTRANCE-PATHWAY-SESSION": "EPS",
  "B2B-GROUP-ENTRANCE-PATHWAY-SESSION": "GES",
  "B2C-ENTRANCE-PATHWAY-SESSION": "EPS",
  "PSYCHOMETRIC-ASSESSMENT-REPORT-INTERPRETATION-SESSION": "AIS",
  "COUNSELLING-1ON1-DEMO-SESSION": "DCS",
  "B2B-GROUP-STUDY-ABROAD-OVERVIEW-SESSION": "GSS"
};

export const getStageShortName = (stageType: string): string => {
  return stageShortNames[stageType] || "N/A";
};
