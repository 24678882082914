import React, {useState} from "react";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {useLocation, useParams} from "react-router-dom";
import {useFetchStageDetailsApi} from "../../stage/services/useStageApi";
import {Spinner} from "flowbite-react";
import {StageGroup} from "../../../core/enums/stage/StageGroup";
import {Stage} from "../../../core/interface/stage/Stage";
import {StageGroupType} from "../../../core/enums/stage/stageGroupType";
import {Grades} from "../../../core/enums/grades";
import SessionStageDetails from "../../stage/components/stageSessionDetails/sessionStageDetails";
import IndividualActionPlan8th9th from "./individual-action-plans/individualActionPlan8th9th";
import {StageType} from "../../../core/enums/stage/stageType";

const CounsellorIndividualSessionsDetails: React.FC = () => {

  const {id} = useParams();
  const location = useLocation();
  const [customerGrade] = useState(location.state?.customerGrade || null);
  const {data: fetchStageDetails, isLoading: isLoadingStageDetails} = useFetchStageDetailsApi(id);
  const stageDetails: Stage | undefined = fetchStageDetails?.data;

  const renderActionPlanStageComponent = () => {
    switch (customerGrade) {
      case Grades.EIGHT:
      case Grades.NINE:
        return (
          <IndividualActionPlan8th9th
            planId={stageDetails?.customer_plan_id}
            stageId={id}
          />
        );
      default:
        return <div>Unknown stage group</div>;
    }
  };

  const renderBreadcrumbLabel = () => {
    if (stageDetails?.stage_group === StageGroup.SESSION) {
      return "Session";
    } else if (stageDetails?.stage_group_type === StageGroupType.ACTION_PLAN_REPORT) {
      if (customerGrade === Grades.EIGHT || customerGrade === Grades.NINE) {
        return "Action Plan (8th, 9th)";
      } else if (customerGrade === Grades.TENTH) {
        return "Action Plan (10th)";
      } else {
        return "Action Plan (Other)";
      }
    } else {
      return "Stage Details";
    }
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton to={`/counsellor-individual-sessions`} label="Back"/>
        <h1 className="text-xl font-bold mb-4">{stageDetails?.stage_name}</h1>
        <CustomBreadcrumb
          items={[
            {label: "Individual Sessions", to: "/counsellor-individual-sessions"},
            {label: renderBreadcrumbLabel()},
          ]}
        />
      </div>
      <div className="border border-gray-300 rounded-md shadow-md p-6 pb-20 bg-white">
        {isLoadingStageDetails ? (
          <div className="flex justify-center items-center py-5">
            <Spinner size="lg"/>
          </div>
        ) : (
          <>
            {stageDetails?.stage_group === StageGroup.SESSION ? (
              <SessionStageDetails
                planId={stageDetails?.customer_plan_id}
                stageId={id}
                stageStatus={stageDetails?.stage_state}
                stageType={stageDetails?.stage_type}
                sessionName={stageDetails?.dynamic_data?.SESSION_DATA?.name}
                datetime={stageDetails?.dynamic_data?.SESSION_DATA?.datetime}
                durationInMins={stageDetails?.dynamic_data?.SESSION_DATA?.duration_in_mins}
                mode={stageDetails?.dynamic_data?.SESSION_DATA?.mode}
                hostName={stageDetails?.dynamic_data?.SESSION_DATA?.host_name}
                meetingLinkHost={stageDetails?.dynamic_data?.SESSION_DATA?.meeting_link_host}
                sessionType='IndividualSession'
                navigateUrl='/counsellor-individual-sessions'
              />
            ) : (
              <>
                {stageDetails?.stage_type === StageType.COUNSELLOR_CREATE_ACTION_PLAN ? (
                  <>{renderActionPlanStageComponent()}</>
                ) : (
                  <div>Unknown stage group</div>
                )
                }
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CounsellorIndividualSessionsDetails;
