export enum SessionMode {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  BOTH_ONLINE_OFFLINE = "BOTH-ONLINE-OFFLINE"
}

export enum SessionAudience {
  BULK = "BULK",
  GROUP = "GROUP",
  INDIVIDUAL = "INDIVIDUAL"
}

export enum HostUserType {
  COUNSELLOR = "COUNSELLOR",
  EXPERT = "EXPERT",
  TRAINER = "TRAINER",
}
