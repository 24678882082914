import React, {useState} from "react";
import {formatString} from "../../../utils/formatString";
import {Button, Spinner} from "flowbite-react";
import {DbContentInterface, Strategy} from "../../../../core/interface/db-content/DbContentInterface";
import {useFetchDbContentApi} from "../services/useDbContentApi";
import {
  SelectedSession
} from "../../../../core/interface/counsellor-individual-sessions/action-plan-db-content/SelectedSession";
import StrategyList from "./Strategy-list/strategyList";
import {ActionPlanDbContentProps} from "../../../../core/interface/db-content/props/ActionPlanDbContentProps";

const ActionPlanDbContent: React.FC<ActionPlanDbContentProps> = (props) => {

  const [selectedActionPlanDbContent, setSelectedActionPlanDbContent] = useState<SelectedSession | null>(
    props.selectedActionPlanDbContent || null
  );

  const {data, isLoading} = useFetchDbContentApi(props.keyProps);
  const dbContent: DbContentInterface | undefined = data?.data;

  // Map actionPlanContent to corresponding key in DbContentInterface
  const getStrategiesByActionPlanContent = (actionPlanContent: string | null): Strategy[] => {
    if (!dbContent || !actionPlanContent) return [];
    const mapping: Record<string, Strategy[]> = {
      "learning_style_strategies": dbContent.learning_style_strategies,
      "aptitude_interest_development_strategies": dbContent.aptitude_interest_development_strategies,
      "personality_development_strategies": dbContent.personality_development_strategies,
      "job_satisfaction_strategies": dbContent.job_satisfaction_strategies,
      "eq_strategies": dbContent.eq_strategies,
    };
    return mapping[actionPlanContent] || [];
  };

  const strategies = getStrategiesByActionPlanContent(props?.keyProps);

  const handleStrategyClick = (index: string, strategy: string, title: string) => {
    const selectedData: SelectedSession = {index, title, strategy};
    setSelectedActionPlanDbContent(selectedData);
  };

  const handleAdd = () => {
    if (selectedActionPlanDbContent) {
      props.onAdd(selectedActionPlanDbContent); // Pass the selected data to the parent
    }
  };

  return (
    <div>
      <div className="mb-5">
        <h1 className="text-xl font-bold mb-4">{formatString(props?.keyProps)}</h1>
      </div>
      <div className="flex flex-col space-y-4 border border-gray-300 rounded-md shadow-md p-6 pb-20 bg-white mb-4">
        {isLoading ? (
          <div className="flex justify-center items-center py-5">
            <Spinner size="lg"/>
          </div>
        ) : strategies.length === 0 ? (
          <div className="flex justify-center items-center py-5">
            <div className="text-center text-gray-500">No {formatString(props?.keyProps)} records found.</div>
          </div>
        ) : (
          <>
            <StrategyList
              strategies={strategies}
              selectedStrategy={selectedActionPlanDbContent?.strategy || null}
              onSelect={handleStrategyClick}
            />
            <div>
              <Button onClick={handleAdd} disabled={!selectedActionPlanDbContent} color="blue" size="xl">
                Add
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ActionPlanDbContent;
