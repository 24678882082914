import React, {useState} from "react";
import {Button, Checkbox, Label, Spinner} from "flowbite-react";
import {useFetchDbContentApi} from "../services/useDbContentApi";
import {DbContentInterface} from "../../../../core/interface/db-content/DbContentInterface";
import {SubjectInterestDbContentProps} from "../../../../core/interface/db-content/props/SubjectInterestDbContentProps";

const SubjectInterestDbContent: React.FC<SubjectInterestDbContentProps> = (props) => {

  const [selectedInterestSubjects, setSelectedInterestSubjects] = useState<string[]>(props.selectedInterestSubjects || []);

  const {data, isLoading} = useFetchDbContentApi(props.keyProps);
  const dbContent: DbContentInterface | undefined = data?.data;

  const handleSubjectToggle = (subject: string) => {
    setSelectedInterestSubjects((prev) =>
      prev.includes(subject)
        ? prev.filter((s) => s !== subject)
        : [...prev, subject]
    );
  };

  const handleAdd = () => {
    if (selectedInterestSubjects) {
      props.onAdd(selectedInterestSubjects);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="mb-5">
        <h1 className="text-xl font-bold mb-4">Subject Interest</h1>
      </div>

      <div className="flex flex-col space-y-4 border border-gray-300 rounded-md shadow-md p-6 bg-white mb-4 flex-grow">
        <h3 className="bg-blue-50 text-xl font-bold p-4 rounded-md mb-4">Subject Interest</h3>
        {isLoading ? (
          <div className="flex justify-center items-center py-5">
            <Spinner size="lg"/>
          </div>
        ) : dbContent?.subjects_interest.length === 0 ? (
          <div className="flex justify-center items-center py-5">
            <div className="text-center text-gray-500">No subject records found.</div>
          </div>
        ) : (
          <>
            <div className="space-y-3 overflow-y-auto max-h-72">
              {dbContent?.subjects_interest.map((subject) => {
                const isSelected = selectedInterestSubjects.includes(subject);
                return (
                  <div
                    key={subject}
                    className={`flex items-center border rounded-md p-2 cursor-pointer ${
                      isSelected ? "border-black bg-gray-100" : "border-gray-300 bg-white"
                    }`}
                    onClick={() => handleSubjectToggle(subject)}
                  >
                    <Checkbox
                      color="blue"
                      id={subject}
                      checked={isSelected}
                      onChange={() => handleSubjectToggle(subject)}
                    />
                    <Label htmlFor={subject} className="ml-2">
                      {subject}
                    </Label>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-start mt-4">
              <Button onClick={handleAdd} disabled={selectedInterestSubjects.length === 0} color="blue" size="xl">
                Add
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SubjectInterestDbContent;
