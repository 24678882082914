import React, {useState} from "react";
import {ConfirmationWithInputBoxDialogProps} from "../../../core/interface/ConfirmDialogProps";
import {Button, Modal} from "flowbite-react";
import CustomLoaderButton from "../custom-buttons/customLoaderButton";
import FormTextarea from "../form-wrapper/FormTextarea";

const ConfirmationWithInputBoxDialog: React.FC<ConfirmationWithInputBoxDialogProps> = (props: ConfirmationWithInputBoxDialogProps) => {

  const [inputBoxValue, setInputBoxValue] = useState<string>('');

  const handleSubmit = () => {
    const data = {
      inputBoxValue
    }
    props.onSubmit(data);
  };

  return (
    <Modal show={props.isVisible} size="md" dismissible={false}>
      <Modal.Body>
        <div>
          {props.icon && (
            <div className="flex justify-center">
              <div className="bg-[#FFEDED] flex flex-col justify-center items-center rounded-full w-14 h-14">
                {props.icon}
              </div>
            </div>
          )}
          {props.title && (
            <h1 className={`text-center text-lg font-bold`}>
              {props.title}
            </h1>
          )}
          <h3 className="text-center mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {props.subTitle}
          </h3>

          <div className="bg-blue-50 rounded-md p-5 mb-5">
            <FormTextarea
              className="flex flex-col justify-start items-start col-span-2 w-full"
              subClassName="h-40 p-2"
              label='Rejection Note'
              placeholder='Enter rejection note....'
              value={inputBoxValue}
              onChangeInput={(e) => setInputBoxValue(e.target.value)}
            />
          </div>

          <div className="flex justify-center gap-4">
            <CustomLoaderButton
              size="lg"
              color={props.submitButtonClass}
              type="button"
              loading={props.loading}
              onClick={handleSubmit}>
              {props.submitButtonText}
            </CustomLoaderButton>
            <Button size='lg' outline color="blue" onClick={props.onClose}>
              {props.cancelButtonText}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationWithInputBoxDialog;
