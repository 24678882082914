import axios from "axios";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {
  CounsellorGroupApprovalRequestInterface
} from "../../../core/interface/counsellor-approval/CounsellorGroupApprovalRequestInterface";
import {
  CounsellorIndividualApprovalRequestInterface
} from "../../../core/interface/counsellor-approval/CounsellorIndividualApprovalRequestInterface";

// Fetch individual approval requests
export const fetchIndividualApprovalRequestsApi = async (page: number, pageSize: number): Promise<CustomResponse<CounsellorIndividualApprovalRequestInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<CounsellorIndividualApprovalRequestInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/counsellor/approval-requests`,
    {params: queryParams}
  );
  return response.data;
};

// Counsellor individual report
export const fetchCounsellorIndividualReport = async (planId: any, stageId: any): Promise<DetailsCustomDataResponse<any>> => {
  const response = await axios.get<DetailsCustomDataResponse<any>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/counsellor/review/plan/${planId}/stage/${stageId}`
  );
  return response.data;
};

// Approve the individual approval request
export const approveIndividualApprovalRequestApi = async (planId: any, stageId: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}/stage/${stageId}/customer-action-plan-approve`, '');
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Reject the individual approval request
export const rejectIndividualApprovalRequestApi = async (planId: any, stageId: any, data: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/plan/${planId}/stage/${stageId}/customer-action-plan-reject`, data);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Fetch group approval requests
export const fetchGroupApprovalRequestsApi = async (page: number, pageSize: number): Promise<CustomResponse<CounsellorGroupApprovalRequestInterface[]>> => {
  const queryParams = new URLSearchParams({
    page_num: page.toString(),
    size: pageSize.toString(),
  });
  const response = await axios.get<CustomResponse<CounsellorGroupApprovalRequestInterface[]>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/counsellor/group-action-plan/approval-requests`,
    {params: queryParams}
  );
  return response.data;
};

// Counsellor group report
export const fetchCounsellorGroupReport = async (groupId: any): Promise<DetailsCustomDataResponse<any>> => {
  const response = await axios.get<DetailsCustomDataResponse<any>>(
    `${process.env.REACT_APP_BASE_URL}/v1/staffs/counsellor/review/group/${groupId}`
  );
  return response.data;
};

// Approve the group approval request
export const approveGroupApprovalRequestApi = async (groupId: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/group/${groupId}/group-action-plan-approve`, '');
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}

// Reject the group approval request
export const rejectGroupApprovalRequestApi = async (groupId: any, data: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/staffs/group/${groupId}/group-action-plan-reject`, data);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    }
    throw new Error('An unexpected error occurred.');
  }
}
