import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useCompleteGroupSessionApi, useFetchCounsellorGroupDetailsApi} from "../services/useCounsellorGroupSessionApi";
import {
  CounsellorGroupSessionDetailsInterface
} from "../../../core/interface/counsellor-group-sessions/CounsellorGroupSessionDetailsInterface";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import {Spinner, Table} from "flowbite-react";
import ProfileField from "../../../shared/components/profile-field/profileField";
import {dateAndTimeFormatter} from "../../../shared/utils/dateAndTimeFormatter";
import TimeRange from "../../../shared/components/date-and-time/timeRange";
import {FaCheckCircle} from "react-icons/fa";
import CustomLoaderButton from "../../../shared/components/custom-buttons/customLoaderButton";

const CounsellorGroupSessionDetails: React.FC = () => {

  const navigate = useNavigate();
  const currentDate = new Date();
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const {mutate: completeGroupSession} = useCompleteGroupSessionApi();
  const {
    data: fetchGroupSessionDetails,
    isLoading: isLoadingGroupSessionDetails
  } = useFetchCounsellorGroupDetailsApi(id);
  const counsellorGroupSessionDetails: CounsellorGroupSessionDetailsInterface | undefined = fetchGroupSessionDetails?.data;

  const sessionDateString = dateAndTimeFormatter(counsellorGroupSessionDetails?.session?.datetime, 'DDD MMM YYYY, h:mm a');
  const sessionDate = sessionDateString ? new Date(sessionDateString) : currentDate;
  const isButtonEnabled = sessionDate <= currentDate;

  const handleCompleteSession = () => {
    setIsLoading(true);
    completeGroupSession({groupId: id}, {
      onSuccess: () => navigate('/counsellor-group-sessions'),
      onSettled: () => setIsLoading(false)
    });
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton to={`/counsellor-group-sessions`} label="Back"/>
        <h1 className="text-xl font-bold mb-4">Counsellor Group Session Details</h1>
        <CustomBreadcrumb
          items={[
            {label: "Counsellor Group Sessions", to: "/counsellor-group-sessions"},
            {label: "Counsellor Group Session Details"},
          ]}
        />
      </div>

      <div className="border border-gray-300 rounded-md shadow-md p-6 pb-20 bg-white">
        {isLoadingGroupSessionDetails ? (
          <div className="flex justify-center items-center py-5">
            <Spinner size="lg"/>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
              <ProfileField label={`Group Name`} value={counsellorGroupSessionDetails?.group_name}/>
              <ProfileField label={`Plan Name`} value={counsellorGroupSessionDetails?.plan_name}/>
              <ProfileField label={`Customers count`} value={counsellorGroupSessionDetails?.customer_count}/>
            </div>

            <div>
              <h3 className='text-lg font-bold mb-4'>Customer List</h3>
              <Table striped>
                <Table.Head className="border-b border-gray-200">
                  <Table.HeadCell className="text-gray-500">Customer Name</Table.HeadCell>
                  <Table.HeadCell className="text-gray-500">Stream</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {counsellorGroupSessionDetails?.customers?.map((customer, index) => (
                    <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>{customer.customer_name}</Table.Cell>
                      <Table.Cell>{customer.stream}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>

            <div>
              <h3 className='text-lg font-bold mb-4'>Session Details</h3>
              <div className="bg-gray-50 p-5">
                <h3 className="text-base font-semibold text-blue-700 mb-4">
                  {counsellorGroupSessionDetails?.session?.name}
                </h3>
                <ProfileField label={`Mode`} value={counsellorGroupSessionDetails?.session?.mode}/>
                <ProfileField label={`Audience`} value={counsellorGroupSessionDetails?.session?.audience}/>
                <ProfileField label={`Duration`} value={counsellorGroupSessionDetails?.session?.duration_in_mins}/>
                <ProfileField label={`Host Name`} value={counsellorGroupSessionDetails?.session?.host_name}/>
                <ProfileField label={`Date`}
                              value={dateAndTimeFormatter(counsellorGroupSessionDetails?.session?.datetime, 'MMMM DD, YYYY')}/>
                <ProfileField label={`Date`}
                              value={<TimeRange startTime={counsellorGroupSessionDetails?.session?.datetime}
                                                duration={counsellorGroupSessionDetails?.session?.duration_in_mins}/>}
                />
                <ProfileField
                  label={`Meeting Link`}
                  value={counsellorGroupSessionDetails?.session?.meeting_link_host}
                  isLink={true}
                />
              </div>

              <CustomLoaderButton loading={isLoading} color="blue" className={`flex items-center`}
                                  disabled={!isButtonEnabled} onClick={handleCompleteSession}>
                {isButtonEnabled && <FaCheckCircle size={18} className={`mr-1`}/>}
                Mark as complete
              </CustomLoaderButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CounsellorGroupSessionDetails;
