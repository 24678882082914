import React from "react";
import { FormFieldProps } from "../../../core/interface/FormFieldProps";
import ErrorText from "./errorText";
import {Label} from "flowbite-react";

const RadioInput: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  const handleChange = (value: any) => {
    if (props.onChangeInput) {
      props.onChangeInput(value); // Call onChangeInput with the selected value
    }
  };

  return (
    <div className={props.className}>
      <Label className="block font-medium text-gray-700">{props.label}</Label>
      <div className="flex items-center space-x-4 mt-2">
        {props.options && props.options.map((option, index) => (
          <label key={option.value} className="flex items-center">
            <input
              type="radio"
              value={option.value}
              checked={props.value === option.value} // Check if this is the selected value
              onChange={() => handleChange(option.value)} // Pass the selected value
              className="form-radio text-blue-600 focus:ring-blue-500"
            />
            <span className="ml-2">{option.label}</span>
          </label>
        ))}
      </div>
      {props.error && <ErrorText message={props.error}/>}
    </div>
  );
};

export default RadioInput;
