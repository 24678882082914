import React from "react";
import moment from "moment-timezone";

interface TimeRangeProps {
  startTime: any;
  duration: any;
}

const TimeRange: React.FC<TimeRangeProps> = ({ startTime, duration }) => {
  if (!startTime || !duration) return <span>N/A</span>;

  try {
    // Convert startTime from UTC to IST
    const startMoment = moment.tz(startTime, "UTC").tz("Asia/Kolkata");

    // Validate startMoment
    if (!startMoment.isValid()) {
      console.error("Invalid startTime:", startTime);
      return <span>Invalid Date</span>;
    }

    // Calculate end time by adding duration
    const endMoment = startMoment.clone().add(duration, "minutes");

    // Format the time range
    const formattedTimeRange = `${startMoment.format("h:mm A")} - ${endMoment.format("h:mm A")}`;

    return <span>{formattedTimeRange}</span>;
  } catch (error) {
    console.error("Error processing time range:", error);
    return <span>Error</span>;
  }
};

export default TimeRange;
