import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CustomResponse, DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {createStaffEntitySessionApi, fetchStaffEntitySessionDetailsApi, fetchStaffEntitySessionsApi} from "./staffEntitySessionApi";
import {useToast} from "../../../shared/components/notification-messages/toastNotification";
import {ToastType} from "../../../core/enums/toastType";
import {StaffEntitySessionInterface} from "../../../core/interface/staff-entity-session/StaffEntitySessionInterface";

export const useGetStaffEntitySessionsApi = (page: number, pageSize: number) => {
  return useQuery<CustomResponse<StaffEntitySessionInterface[]>>({
    queryKey: ['staffEntitySessions', {page, pageSize}],
    queryFn: () => fetchStaffEntitySessionsApi(page, pageSize),
    retry: false,
    staleTime: 0,
  });
};

export const useFetchEntitySessionDetailsApi = (planId: any, stageId: any) => {
  return useQuery<DetailsCustomDataResponse<StaffEntitySessionInterface>>({
    queryKey: ['staffEntitySession', {planId, stageId}],
    queryFn: () => fetchStaffEntitySessionDetailsApi(planId, stageId),
    enabled: !!planId && !!stageId,
  });
};

export const useCreateEntitySessionApi = () => {
  const {showToastMessage} = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({planId, stageId, data}: {
      planId: any,
      stageId: any,
      data: any
    }) => createStaffEntitySessionApi(planId, stageId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['staffEntitySessions']});
      const message = 'Entity session created successfully!';
      showToastMessage({type: ToastType.Success, message});
    },
    onError: (error: any) => {
      const message = error.detail || 'Something went wrong.';
      showToastMessage({type: ToastType.Error, message});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['staffEntitySessions']});
    }
  })
}
