import React, {useState} from "react";
import {Button} from "flowbite-react";
import {HiOutlinePlusSm, HiOutlineMinusSm} from "react-icons/hi";
import ResourceCard from "./resourceCard";
import {
  ResourceCardListProps,
  ResourceRequestProps
} from "../../../../../core/interface/counsellor-individual-sessions/ResourceRequestProps";

const ResourceCardList: React.FC<ResourceCardListProps> = ({onResourcesChange}) => {
  const [resources, setResources] = useState<ResourceRequestProps[]>([
    {id: 1, title: "", urls: [""]},
  ]);

  const updateResources = (updatedResources: ResourceRequestProps[]) => {
    setResources(updatedResources);
  };

  const handleAddResource = () => {
    updateResources([
      ...resources,
      {id: resources.length + 1, title: "", urls: [""]},
    ]);
  };

  const handleRemoveResource = (id: number) => {
    updateResources(resources.filter((resource) => resource.id !== id));
  };

  const handleUpdateTitle = (id: number, value: string) => {
    updateResources(
      resources.map((resource) =>
        resource.id === id ? {...resource, title: value} : resource
      )
    );
  };

  const handleAddUrl = (id: number) => {
    updateResources(
      resources.map((resource) =>
        resource.id === id ? {...resource, urls: [...resource.urls, ""]} : resource
      )
    );
  };

  const handleUpdateUrl = (id: number, index: number, value: string) => {
    updateResources(
      resources.map((resource) =>
        resource.id === id
          ? {
            ...resource,
            urls: resource.urls.map((url, i) =>
              i === index ? value : url
            ),
          }
          : resource
      )
    );
  };

  const handleRemoveUrl = (id: number, index: number) => {
    updateResources(
      resources.map((resource) =>
        resource.id === id
          ? {...resource, urls: resource.urls.filter((_, i) => i !== index)}
          : resource
      )
    );
  };

  const filteredResources = resources
    .filter((resource) => resource.title.trim() || resource.urls.some((url) => url.trim()))
    .map(({title, urls}) => ({
      title,
      urls: urls.filter((url) => url.trim()),
    }));
  onResourcesChange(filteredResources);

  return (
    <div className="p-4 space-y-4">
      {resources.map((resource, index) => (
        <div key={resource.id} className="flex items-start space-x-4">
          <div className={`w-1/2`}>
            <ResourceCard
              resource={resource}
              onUpdateTitle={handleUpdateTitle}
              onAddUrl={handleAddUrl}
              onUpdateUrl={handleUpdateUrl}
              onRemoveUrl={handleRemoveUrl}
            />
          </div>

          <div className="flex flex-col space-y-2">
            {resources.length > 1 && index < resources.length - 1 && (
              <Button
                onClick={() => handleRemoveResource(resource.id)}
                className="bg-blue-100 text-black hover:text-white"
              >
                <HiOutlineMinusSm size={24}/>
              </Button>
            )}
            {index === resources.length - 1 && (
              <Button
                onClick={handleAddResource}
                className="bg-blue-100 text-black hover:text-white"
              >
                <HiOutlinePlusSm size={24}/>
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResourceCardList;
