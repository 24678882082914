import React, {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
  useApproveIndividualApprovalRequestApi,
  useFetchCounsellorIndividualReport,
  useRejectIndividualApprovalRequestApi,
} from "../services/useCounsellorApprovalRequestApi";
import BackButton from "../../../shared/components/custom-buttons/backButton";
import CustomBreadcrumb from "../../../shared/components/custom-breadcrumb/customBreadcrumb";
import PdfViewer from "../../../shared/components/pdf-viewer/PdfViewer";
import {Button, Spinner} from "flowbite-react";
import ConfirmDialog from "../../../shared/components/modals/confirmDialog";
import ConfirmationWithInputBoxDialog from "../../../shared/components/modals/confirmationWithInputBoxDialog";
import {HiExclamation} from "react-icons/hi";

const CounsellorIndividualApprovalRequestDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams<{ id: string }>();
  const planId = location.state?.planId;

  const [dialogState, setDialogState] = useState({
    showApprovalDialog: false,
    showRejectionDialog: false,
  });

  const [loadingState, setLoadingState] = useState({
    approving: false,
    rejecting: false,
  });

  const {data, isLoading} = useFetchCounsellorIndividualReport(planId, id);

  const {mutate: approveRequest} = useApproveIndividualApprovalRequestApi();
  const {mutate: rejectRequest} = useRejectIndividualApprovalRequestApi();

  const openApprovalDialog = () => setDialogState({...dialogState, showApprovalDialog: true});

  const openRejectionDialog = () => setDialogState({...dialogState, showRejectionDialog: true});

  const handleApproval = () => {
    setLoadingState({...loadingState, approving: true});

    approveRequest(
      {planId, stageId: id},
      {
        onSuccess: () => {
          navigate("/counsellor-approval-requests");
          setDialogState({...dialogState, showApprovalDialog: false});
        },
        onSettled: () => setLoadingState({...loadingState, approving: false}),
      }
    );
  };

  const handleRejection = (data: { inputBoxValue: string }) => {
    setLoadingState({...loadingState, rejecting: true});

    rejectRequest(
      {
        planId,
        stageId: id,
        data: {notes: data.inputBoxValue},
      },
      {
        onSuccess: () => {
          navigate("/counsellor-approval-requests");
          setDialogState({...dialogState, showRejectionDialog: false});
        },
        onSettled: () => setLoadingState({...loadingState, rejecting: false}),
      }
    );
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton to="/counsellor-approval-requests" label="Back"/>
        <h1 className="text-xl font-bold mb-4">Approval Request Details</h1>
        <CustomBreadcrumb
          items={[
            {label: "Approval Requests", to: "/counsellor-approval-requests"},
            {label: "Approval Request Details"},
          ]}
        />
      </div>

      <div className="border border-gray-300 rounded-md shadow-md p-6 bg-white mb-8">
        {isLoading ? (
          <div className="flex justify-center items-center py-5">
            <Spinner size="lg"/>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 shadow-md p-5 flex flex-col justify-center items-center space-y-5 mb-4">
              <PdfViewer pdfUrl={data?.data}/>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <Button color="blue" onClick={openApprovalDialog}>
                Approve
              </Button>
              <Button outline color="blue" onClick={openRejectionDialog}>
                Reject
              </Button>
            </div>
          </>
        )}
      </div>

      <ConfirmDialog
        title="Approve"
        submitButtonText="Yes"
        submitButtonClass="blue"
        cancelButtonText="Cancel"
        showCancelButton
        loading={loadingState.approving}
        isVisible={dialogState.showApprovalDialog}
        onClose={() => setDialogState({...dialogState, showApprovalDialog: false})}
        onClick={handleApproval}
        subTitle="Are you sure you want to approve the report?"
      />

      <ConfirmationWithInputBoxDialog
        submitButtonText="Confirm"
        submitButtonClass="blue"
        cancelButtonText="Cancel"
        showCancelButton
        loading={loadingState.rejecting}
        icon={<HiExclamation size={30} color="red"/>}
        isVisible={dialogState.showRejectionDialog}
        onClose={() => setDialogState({...dialogState, showRejectionDialog: false})}
        onSubmit={handleRejection}
        title="Do you want to reject?"
        subTitle="Please leave a rejection note."
      />
    </div>
  );
};

export default CounsellorIndividualApprovalRequestDetails;
