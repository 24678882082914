import {useQuery} from "@tanstack/react-query";
import {DetailsCustomDataResponse} from "../../../core/interface/CustomResponse";
import {fetchStageDetailsApi} from "./stageApi";
import {Stage} from "../../../core/interface/stage/Stage";

export const useFetchStageDetailsApi = (stageId: any) => {
  return useQuery<DetailsCustomDataResponse<Stage>>({
    queryKey: ['customerPlanStage', {stageId}],
    queryFn: () => fetchStageDetailsApi(stageId),
    enabled: !!stageId
  });
};
